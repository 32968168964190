import Vue from 'vue';
import Vuex from 'vuex';
import {
  SET_APP_BAR_TYPE_ACTION,
  MOBILE_SCREEN_SIZE,
  SHOW_LOGIN_DIALOG,
  SHOW_SNACKBAR,
  SHOW_SNACKBAR_COMMON_ERROR_ACTION,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
  REDIRECT_TO_ACTION,
  LOGOUT,
} from '@/store/_actionTypes';
import {
  SET_APP_BAR_TYPE,
  SET_MOBILE_SCREEN_SIZE,
  SET_SHOW_LOGIN_DIALOG,
  SET_SHOW_SNACKBAR,
  SET_SNACKBAR_MESSAGE,
  SET_SNACKBAR_COLOR,
} from '@/store/_mutationTypes';

import PhoneNumberLogo from '@/assets/images/logo/25377.svg';
import LineLogo from '@/assets/images/logo/icons-8-line_2.svg';
import FacebookLogo from '@/assets/images/logo/f-logo-rgb-blue-1024.png';
import UserIcon from '@/assets/images/logo/ic-profile-user.svg';
import OrderHistoryIcon from '@/assets/images/logo/ic-order-history.svg';
import RequestProductIcon from '@/assets/images/logo/ic-request-product.svg';
import WishlistIcon from '@/assets/images/logo/ic-wishlist.svg';
import ReferralIcon from '@/assets/images/logo/ic-referral.svg';
import LogoutIcon from '@/assets/images/logo/logout.svg';

Vue.use(Vuex);

export const APP_BAR_A_VERSION = 'APP_BAR_A_VERSION'; // online shopping
export const APP_BAR_B_VERSION = 'APP_BAR_B_VERSION'; // career

const states = {
  appBarType: APP_BAR_A_VERSION,
  contactMenus: [
    { title: '088-886-8628', iconImg: PhoneNumberLogo, redirectTo: 'tel:0888868628', gtag: 'AW-966302140/aq49COrKvIQDELyz4swD' },
    { title: '@narinonline', iconImg: LineLogo, redirectTo: 'https://lin.ee/59vJm71', isExternalLink: true, guestRedirectTo: 'https://lin.ee/kiECl11', gtag: 'AW-966302140/gcCNCNfKxYQDELyz4swD' },
    { title: 'narinbhaesaj', iconImg: FacebookLogo, redirectTo: 'https://www.facebook.com/narinbhaesaj', isExternalLink: true, gtag: 'AW-966302140/RcAvCMfqxYQDELyz4swD' },
  ],
  pageMenus: [
    { title: 'ทำไมต้องซื้อกับเรา', redirectTo: 'Why2Buy', isExternalLink: false },
    { title: 'การเปลี่ยนคืนสินค้า', redirectTo: 'ReturnPolicy', isExternalLink: false },
    { title: 'วิธีการสั่งซื้อ', redirectTo: 'How2Buy', isExternalLink: false },
    { title: 'อัตราค่าจัดส่ง', redirectTo: 'DeliveryInfo', isExternalLink: false },
    { title: 'ประกาศจาก Admin', redirectTo: 'Announcement', isExternalLink: false },
    { title: 'ติชม / ร้องเรียน', redirectTo: 'Feedback', isExternalLink: false },
    { title: 'แจ้งชำระเงิน', redirectTo: '/order-history?filter=pending_payment', isExternalLink: true },
  ],
  publicPageMenus: [
    { title: 'หน้าหลัก', redirectTo: 'Home', externalRedirectTo: '/', isExternalLink: false },
    { title: 'เกี่ยวกับเรา', redirectTo: 'AboutUs', externalRedirectTo: '/about-us', isExternalLink: false },
    { title: 'สมัครงาน', redirectTo: 'career', externalRedirectTo: '/career', isExternalLink: true },
    { title: 'ติดต่อเรา', redirectTo: 'ContactUs', externalRedirectTo: '/contact-us', isExternalLink: false },
  ],
  profileMenus: [
    { title: 'ข้อมูลส่วนตัว', iconImg: UserIcon, redirectTo: 'UserInfo', externalRedirectTo: '/user-info', isExternalLink: false },
    { title: 'ประวัติการสั่งซื้อ', iconImg: OrderHistoryIcon, redirectTo: 'OrderHistory', externalRedirectTo: '/order-history', isExternalLink: false },
    { title: 'Request สินค้า', iconImg: RequestProductIcon, redirectTo: 'RequestProduct', externalRedirectTo: '/request-product', isExternalLink: false },
    { title: 'รายการ Wishlist', iconImg: WishlistIcon, redirectTo: 'Wishlist', externalRedirectTo: '/wishlist', isExternalLink: false },
    { title: 'แนะนำเพื่อน', iconImg: ReferralIcon, redirectTo: 'Referral', externalRedirectTo: '/referral', isExternalLink: false },
    { title: 'ออกจากระบบ', iconImg: LogoutIcon, redirectTo: 'Logout', isExternalLink: false },
  ],
  isMobileScreenSize: false,
  isDisplayLoginDialog: false,
  isDisplaySnackBar: false,
  snackbarMessage: '',
  snackbarColor: 'green',
};

const getters = {
  getAppBarType: (state) => state.appBarType,
};

const actions = {
  [SET_APP_BAR_TYPE_ACTION]({
    commit,
  }, {
    type,
  }) {
    if (type !== APP_BAR_A_VERSION && type !== APP_BAR_B_VERSION) {
      return;
    }
    commit(SET_APP_BAR_TYPE, type);
  },
  [MOBILE_SCREEN_SIZE]({ commit }, flag) {
    commit(SET_MOBILE_SCREEN_SIZE, flag);
  },
  [SHOW_LOGIN_DIALOG]({
    commit,
  }, isShow) {
    commit(SET_SHOW_LOGIN_DIALOG, isShow);
  },
  [SHOW_SNACKBAR]({ commit }, isShow) {
    commit(SET_SHOW_SNACKBAR, isShow);
  },
  [SHOW_SNACKBAR_COMMON_ERROR_ACTION]({ commit }) {
    commit(SET_SHOW_SNACKBAR, true);
    commit(SET_SNACKBAR_MESSAGE, 'เกิดข้อผิดพลาด กรุณาลองใหม่ในภายหลัง');
    commit(SET_SNACKBAR_COLOR, 'red');
  },
  [CHANGE_SNACKBAR_MESSAGE]({ commit }, message) {
    commit(SET_SNACKBAR_MESSAGE, message);
  },
  [CHANGE_SNACKBAR_COLOR]({ commit }, color) {
    commit(SET_SNACKBAR_COLOR, color);
  },
  [REDIRECT_TO_ACTION]({}, { isExternalLink, redirectTo, externalRedirectTo, currentRouteName, router }) {
    if (currentRouteName === redirectTo) return;

    if (redirectTo === 'Logout') {
      this.dispatch(`user/${LOGOUT}`, { router, currentRouteName });
      return;
    }

    if (redirectTo.search('tel') !== -1) {
      window.location.href = redirectTo;
      return;
    }

    if (externalRedirectTo !== undefined) {
      window.location.href = externalRedirectTo;
      return;
    }

    if (isExternalLink) {
      window.location.href = redirectTo;
      return;
    }

    router.push({ name: redirectTo });
  }
};

const mutations = {
  [SET_APP_BAR_TYPE](state, type) {
    state.appBarType = type;
  },
  [SET_MOBILE_SCREEN_SIZE](state, flag) {
    state.isMobileScreenSize = flag;
  },
  [SET_SHOW_LOGIN_DIALOG](state, isShow) {
    state.isDisplayLoginDialog = isShow;
  },
  [SET_SHOW_SNACKBAR](state, isShow) {
    state.isDisplaySnackBar = isShow;
  },
  [SET_SNACKBAR_MESSAGE](state, message) {
    state.snackbarMessage = message;
  },
  [SET_SNACKBAR_COLOR](state, color) {
    state.snackbarColor = color;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
