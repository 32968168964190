import Vue from 'vue';
import Vuex from 'vuex';

import {
  getAnnouncements,
} from '@/services/announcement';

import {
  GET_ANNOUNCEMENT,
} from '../_actionTypes';

import { 
  SET_ANNOUNCEMENT,
} from '../_mutationTypes';

Vue.use(Vuex);

const states = {
  announcements: [],
};

const getters = {};

const actions = {
  async [GET_ANNOUNCEMENT]({ commit }, { accessToken }) {
    const result = await getAnnouncements(accessToken);
    commit(SET_ANNOUNCEMENT, result.results);
  },
};

const mutations = {
  [SET_ANNOUNCEMENT](state, value) {
    state.announcements = value;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
