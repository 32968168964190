import { get, post } from '@/helpers/axios';

export const confirmQuotation = (accessToken, order) => {
  try {
    const result = post('/sales', order, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const confirmCoupon = (accessToken, coupon) => {
  try {
    // TODO-coupon: use axios to get coupon and remove temporary coupon object
    const result = get(`/promocodes?code=${coupon}`, {} , accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
