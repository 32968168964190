<template>
  <div>
    <v-dialog
      v-model="isDisplayLoginDialog"
      transition="dialog-top-transition"
      content-class="dialog"
      width="838"
      @keydown.esc="closeDialog"
      @click:outside="closeDialog"
    >
      <div class="card">
        <div class="d-flex justify-end pt-6 pr-6">
          <img
            class="close-img"
            :src="closeIcon"
            @click="closeDialog"
          >
        </div>
        <div class="dialog-background pb-16">
          <div class="d-flex flex-row justify-center align-center">
            <img
              class="narin-logo mr-3"
              :src="narinLogo"
            >
            <span class="title-text">นรินทร์เภสัช</span>
          </div>
          <div class="d-flex justify-sm-end justify-center mr-sm-12">
            <div class="mt-8 align-end d-flex flex-column align-center">
              <v-form
                ref="form"
                class="d-flex flex-column"
                lazy-validation
                @submit="submit"
                onSubmit="return false;"
              >
                <span class="input-text-title">อีเมล</span>
                <v-text-field
                  v-model="email"
                  class="input-text-field mt-2 mb-3"
                  placeholder="อีเมล"
                  hide-details="auto"
                  type="email"
                  color="secondary"
                  :rules="emailRules"
                  required
                  flat
                  dense
                  outlined
                  single-line
                />
                <span class="input-text-title">รหัสผ่าน</span>
                <v-text-field
                  v-model="password"
                  class="input-text-field mt-2 mb-1"
                  placeholder="รหัสผ่าน"
                  hide-details="auto"
                  color="secondary"
                  :rules="passwordRules"
                  :type="getPasswordType"
                  :append-icon="getPasswordIcon"
                  required
                  flat
                  dense
                  outlined
                  single-line
                  @click:append="setShowPassword"
                />
                <a
                  class="forget-password align-self-end"
                  href="/forgetpassword"
                >ลืมรหัสผ่าน</a>
                <v-btn
                  class="mt-3"
                  color="secondary"
                  large
                  block
                  type="submit"
                >
                  เข้าสู่ระบบ
                </v-btn>
              </v-form>
              <div
                v-if="hasErrorMessage"
                class="mt-2"
              >
                <span class="error--text">{{ getErrorMessage }}</span>
              </div>
              <div class="mt-4">
                <span class="register-title">ยังไม่มีบัญชีผู้ใช้?</span>
                <a class="register-a ml-1" href="/register">สมัครสมาชิก</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { LOGIN, SHOW_LOGIN_DIALOG } from '@/store/_actionTypes';
import CloseIcon from '@/assets/images/logo/close.svg';
import { mapState, mapActions, mapGetters } from 'vuex';
import NarinLogo from '@/assets/images/logo/nrn-logo.png';

export default {
  name: 'LoginDialog',
  data: () => ({
    closeIcon: CloseIcon,
    narinLogo: NarinLogo,
    showPassword: false,
    email: '',
    emailRules: [],
    password: '',
    passwordRules: [],
  }),
  computed: {
    ...mapState('app', ['isDisplayLoginDialog']),
    ...mapGetters('user', ['getErrorMessage']),
    getPasswordIcon() {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
    },
    getPasswordType() {
      return this.showPassword ? 'text' : 'password';
    },
    hasErrorMessage() {
      return !!this.getErrorMessage;
    },
  },
  watch: {
    email() {
      this.emailRules = [];
    },
    password() {
      this.passwordRules = [];
    },
    isDisplayLoginDialog() {
      if (!this.isDisplayLoginDialog) {
        this.clearDialogData();
      }
    },
  },

  methods: {
    ...mapActions('app', [SHOW_LOGIN_DIALOG]),
    ...mapActions('user', [LOGIN]),
    setShowPassword() {
      this.showPassword = !this.showPassword;
    },
    closeDialog() {
      this[SHOW_LOGIN_DIALOG](false);
    },
    clearDialogData() {
      this.email = '';
      this.password = '';
      this.$refs.form.resetValidation();
    },
    submit() {
      this.emailRules = [
        (v) => !!v || 'กรุณากรอกอีเมล',
        (v) => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง',
      ];
      this.passwordRules = [(v) => !!v || 'กรุณากรอกรหัสผ่าน'];
      this.errorMessage = '';
      setTimeout(() => {
        this.login();
      });
    },
    async login() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        await this[LOGIN]({
          email: this.email,
          password: this.password,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.v-dialog__content {
  align-items: flex-start !important;
}

.dialog {
  box-shadow: none !important;

  .v-input {
    font-family: Kanit;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #999999;
  }
}

.card {
  border-radius: 16px !important;
  background-color: white;
}

.dialog-background {
  @media only screen and (min-width: 576px) {
    background-image: url('../../assets/images/background/nrn-dialog-bg.svg');
    background-repeat: no-repeat;
    position: relative;
    border-radius: 16px;
  }
}

.close-img {
  width: 14px;
  height: 14px;

  &:hover {
    cursor: pointer;
  }
}

.narin-logo {
  width: 70px;
  height: 45px;
}

.title-text {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #242424;
}

.input-text-title {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #242424;
}

.input-text-field {
  @media only screen and (min-width: 576px) {
    width: 338px;
  }
}

.forget-password {
  font-size: 12px;
  color: #953dff !important;

  &:hover {
    text-decoration: underline;
  }
}

.dialog-background-img {
  position: absolute;
  height: fit-content;
}

.register-title {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.register-a {
  color: #953dff !important;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  &:hover {
    text-decoration: underline;
  }
}
</style>
