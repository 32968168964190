var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('div',{class:_vm.getClassName},_vm._l((_vm.menuList),function(ref,key){
var title = ref.title;
var iconImg = ref.iconImg;
var isExternalLink = ref.isExternalLink;
var redirectTo = ref.redirectTo;
var externalRedirectTo = ref.externalRedirectTo;
var guestRedirectTo = ref.guestRedirectTo;
var gtag = ref.gtag;
return _c('div',{key:key,staticClass:"d-flex align-center mr-4 py-2",on:{"click":function($event){return _vm.clickMenu({ isExternalLink: isExternalLink, redirectTo: redirectTo, externalRedirectTo: externalRedirectTo, guestRedirectTo: guestRedirectTo, gtag: gtag })}}},[_vm._t("default"),(_vm.hasIconImage(iconImg))?_c('img',{staticClass:"img",attrs:{"src":iconImg}}):_vm._e(),(_vm.hasTitle(title))?_c('span',{class:_vm.getTitleClassName(iconImg)},[_vm._v(_vm._s(_vm.getUserLineId(title)))]):_vm._e()],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }