import { post } from '@/helpers/axios';

export const confirmPayment = (orderId, fileId, paymentBank, accessToken) => {
  try {
    return post(`/sales/${orderId}/action/update_payment`, { fileId, paymentBank }, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
