<template>
  <v-footer
    color="#1c033a"
    class="footer__copyright d-flex justify-center py-3"
  >
    <span>Narin Pharmacy CO., LTD. Since 1983</span>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterCopyright',
};
</script>

<style scoped>
.footer__copyright > span {
  opacity: 0.7;
  font-family: Muli;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .footer__copyright > span {
    font-size: 10px;
  }
}
</style>
