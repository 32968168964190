import Vue from 'vue';
import Vuex from 'vuex';

import {
  getWishlist,
  addWishlist,
  removeWishlist,
} from '@/services/product';

import {
  GET_WISHLIST_PRODUCT,
  ADD_WISHLIST_PRODUCT,
  REMOVE_WISHLIST_PRODUCT,
} from '@/store/_actionTypes';

import {
  SET_WISHLIST_PRODUCT,
  UPDATE_WISHLIST_PRODUCT,
} from '@/store/_mutationTypes';

Vue.use(Vuex);

const states = {
  wishlistProduct: [],
};

const getters = {
  
};

const actions = {
  async [GET_WISHLIST_PRODUCT]({ commit }, accessToken) {
    const result = await getWishlist(accessToken);
    commit(SET_WISHLIST_PRODUCT, result);
  },
  async [ADD_WISHLIST_PRODUCT]({ commit }, { product, accessToken }) {
    await addWishlist(product.id, accessToken);
  },
  async [REMOVE_WISHLIST_PRODUCT]({ commit }, { product, accessToken }) {
    await removeWishlist(product.id, accessToken);
    commit(UPDATE_WISHLIST_PRODUCT, product);
  },
};

const mutations = {
  [SET_WISHLIST_PRODUCT](state, product) {
    state.wishlistProduct = product;
  },
  [UPDATE_WISHLIST_PRODUCT](state, product) {
    state.wishlistProduct = state.wishlistProduct.filter(item => item.id != product.id);
  }
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
