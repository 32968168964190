<template>
  <div class="d-flex">
    <div
      v-for="(menu, key) in menus"
      :key="key"
      class="page-menu d-flex flex-column align-center"
      @click="clickMenu(menu)"
    >
      <span class="title-text">{{ menu.title }}</span>
      <div v-if="getDividerActive(menu.title)" class="hr-container mt-2">
        <v-divider class="hr" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { REDIRECT_TO_ACTION } from '@/store/_actionTypes';

export default {
  name: 'TabMenu',
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions('app', [REDIRECT_TO_ACTION]),
    clickMenu({ isExternalLink, redirectTo, externalRedirectTo }) {
      this[REDIRECT_TO_ACTION]({ isExternalLink, redirectTo, externalRedirectTo, currentRouteName: this.$route.name, router: this.$router });
    },
    getDividerActive(title) {
      const currentRouteName = this.$route.name;
      if (currentRouteName === 'ContactUs' && title === 'ติดต่อเรา') {
        return true;
      } else if ((currentRouteName === 'Career' || currentRouteName === 'Application') && title === 'สมัครงาน') {
        return true;
      } else if (currentRouteName === 'AboutUs' && title === 'เกี่ยวกับเรา') {
        return true;
      } else if (currentRouteName === 'Home' && title === 'หน้าหลัก') {
        return true;
      } else {
        return false;
      }
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/fonts.scss';
@import '@/styles/variables.scss';

.page-menu {
  cursor: pointer;
  margin-right: 40px;

  .title-text {
    @include kanit;
    font-size: 16px;
    line-height: 1.5;
    color: $white-00;
  }

  .hr-container {
    width: 40px;
    
    .hr {
      border: 2px solid $green-color-02 !important;
    }
  }
}
</style>