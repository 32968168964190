import Vue from 'vue';
import Vuex from 'vuex';

import { cancelOrder, getOrders, getOrderById } from '@/services/order';
import router from '@/pages/main/router';
import { getPickupLocationById } from '@/helpers/pickupLocations';
import { BANKS, getPaymentOptionTitle, getBankDetail } from '@/helpers/payment';
import { PROVIDERS, getProviderDetail, getProviderDetailByName } from '@/helpers/deliveryProviders';
import { ORDER_LOGISTIC_STEPS, BANK_STEPS, CASH_STEPS, CREDIT_LOGISTIC_STEPS, CREDIT_STEPS } from '@/helpers/orderSteps';
import  { formatDateWithTimePrefix } from "@/helpers/date";
import  { formatNumber } from "@/helpers/number";

import {
  CANCEL_ORDER, CHANGE_SNACKBAR_COLOR,
  CHANGE_SNACKBAR_MESSAGE,
  SHOW_CANCEL_ORDER_DIALOG,
  SHOW_SNACKBAR,
  SHOW_SNACKBAR_COMMON_ERROR_ACTION,
  GET_ORDERS_ACTION,
  GET_ORDER_BY_ID_ACTION,
} from '../_actionTypes';

import {
  SET_SHOW_CANCEL_ORDER_DIALOG,
  SET_ORDER_MUTATION,
  SET_ORDER_BY_ID_MUTATION,
  SET_ORDER_PAGE,
  SET_ORDER_TOTAL,
} from '../_mutationTypes';

Vue.use(Vuex);
const states = {
  isShowCancelOrderDialog: false,
  orders: [],
  order: {},
  deliveryProviders: PROVIDERS,
  page: 1,
  total: 1,
};

const getters = {
  paymentMethod: (state) => state.order?.payment?.method || 0,
  pickupLocation: (state) => {
    const shippingType = state.order?.shippingType;
    let pickupLocationType = 0;

    switch (shippingType) {
      case 'store_pickup':
        pickupLocationType = 1;
        break;
      case 'store_delivery':
        pickupLocationType = 2;
        break;
      case 'city_pickup':
        pickupLocationType = 3;
        break;
      case 'logistic':
        pickupLocationType = 4;
        break;
      default:
        pickupLocationType = 0;
        break;
    };

    const pickupLocation = getPickupLocationById(pickupLocationType);
    if (!pickupLocation) return 'ไม่ระบุ';

    let text = `${pickupLocation.title}`;

    if (state.order?.orderStatus === 'pending_payment') return text;

    const deliveryProvider = state.order?.shippingNumber;
    if (!!deliveryProvider && pickupLocationType === 4) {
      const providerDetail = getProviderDetail(deliveryProvider);
      text = `${text}: ${providerDetail?.name || 'ไม่ระบุ'}`
    }

    return text;
  },
  orderSteps: (state) => {
    if (state.order?.isCreditOrder && state.order?.shippingType === 'logistic')
      return CREDIT_LOGISTIC_STEPS;

    if (state.order?.isCreditOrder)
      return CREDIT_STEPS;

    if (state.order?.shippingType === 'logistic')
      return ORDER_LOGISTIC_STEPS;

    if (state.order?.paymentType === 'transfer')
      return BANK_STEPS;

    if (state.order?.paymentType === 'cash')
      return CASH_STEPS;
  },
  orderStep: (state) => {
    const orderStatus = state.order?.status;
    const isCreditOrder = state.order?.isCreditOrder;
    if (isCreditOrder && orderStatus === 'prepare_order')
      return 1;

    if (isCreditOrder && orderStatus === 'validate_payment')
      return 3;

    switch (orderStatus) {
      case 'pending_payment':
      case 'prepare_order':
        return 1;
      case 'prepare_order':
      case 'pending_delivery':
      case 'pending_recieve':
      case 'credit':
      case 'validate_payment':
        return 2;
      case 'delivered':
      case 'recieved':
      case 'credit_overdue':
      case 'cancel':
      case 'paid':
        return 3;
      default:
        return 1;
    }
  },
  paymentOption: (state) => {
    const payment = state.order?.paymentType;
    const orderStatus = state.order?.orderStatus;
    const paymentBank = state.order?.paymentBank;
    let text = getPaymentOptionTitle(payment);

    if (orderStatus !== 'pending_payment' && payment === 'transfer') {
      if (!paymentBank) return text;
      text = `${text}: ${paymentBank}`
    }

    return text;
  },
  transferDate: (state) => {
    const orderStatus = state.order?.status;
    const payment = state.order?.paymentType;

    if (orderStatus === 'pending_payment' || payment !== 'transfer' || !state.order?.paymentSubmitDate) return '';
    return `วัน/เวลา ที่ชำระเงิน: ${formatDateWithTimePrefix(state.order?.paymentSubmitDate)}`;
  },
  transferPrice: (state) => {
    const orderStatus = state.order?.status;
    const payment = state.order?.paymentType;

    if (orderStatus === 'pending_payment' || payment !== 'transfer') return '';
    return `จำนวนเงินที่ชำระ: ฿${formatNumber(state.order?.totalAmount)}`;
  },
  paymentOptions: (state) => {
    const orderStatus = state.order?.status;
    const payment = state.order?.paymentType;
    const paymentBank = state.order?.paymentBank;

    if (payment === 'cash') return [];

    if (orderStatus === 'pending_payment' && payment === 'transfer') return BANKS;

    if (payment === 'transfer' && orderStatus !== 'pending_payment') {
      const bankDetail = getBankDetail(paymentBank);
      return bankDetail ? [bankDetail] : [];
    }

    return [];
  },
  totalProductPrice: (state) => state.order?.subtotalAmount,
  deliveryPrice: (state) => state.order?.shippingAmount,
  discountPrice: (state) => state.order?.discountAmount,
  totalPrice: (state) => state.order?.totalAmount,
  capsule: (state) => state.order?.capsules,
  isShowInteractionBtns: (state) => state.order?.status === 'pending_payment' || state.order?.status === 'credit',
  deliveryProvider: (state) => {
    if (state.order?.orderStatus === 'pending_payment' && state.order?.shippingType === 'logistic') return {};
    const provider = state.order?.shippingName;
    return getProviderDetailByName(provider);
  },
  deliveryTrackingNumber: (state) => state.order?.shippingNumber,
  isShowTrackingNumber: (state) => state.order?.status !== 'pending_payment' && state.order?.shippingType === 'logistic',
  getOrderDetails: (state) => {
    return state.order?.productOrderLines?.map(line => {
      return {
        productImage: (!line.product.images || line.product.images.lenght === 0 || !line.product.images[0]) ? '' : line.product.images[0].url,
        productId: line.product.productCode,
        productType: 1,
        productName: line.product.name,
        productGenericName: line.product.genericName,
        productPrice: line.unitPrice,
        amount: line.qty,
        unit: line.product.unit,
        extraAmount: line.freeProductQty,
      }
    })
  },
  getTableOrders: (state) => {
    return state.orders.map(order => {
      return {
        id: order.id,
        orderNumber: order.orderNumber,
        productImages: order.productOrderLines.map(line => {
          if (!line.product.images || line.product.images.lenght === 0)
            return '';

          if (!line.product.images[0])
            return '';

          return line.product.images[0].url;
        }),
        totalPrice: `฿${formatNumber(order.totalAmount)}`,
        latestUpdated: order.updatedAt,
        paymentStatus: order.status,
      }
    });
  },
};

const actions = {
  [SHOW_CANCEL_ORDER_DIALOG]({ commit }, value) {
    commit(SET_SHOW_CANCEL_ORDER_DIALOG, value);
  },
  async [CANCEL_ORDER]({ dispatch }, orderId) {
    try {
      const result = await cancelOrder(orderId);
      if (result) {
        dispatch(SHOW_CANCEL_ORDER_DIALOG, false);
        this.dispatch(`app/${SHOW_SNACKBAR}`, true);
        this.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'ยกเลิกสำเร็จ');
        this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'green');
        router.push('/order-history');
      }
    } catch {
      this.dispatch(`app/${SHOW_SNACKBAR_COMMON_ERROR_ACTION}`);
    }
  },
  async [GET_ORDERS_ACTION]({ commit }, { accessToken, status, page = 1 }) {
    try {
      const result = await getOrders(accessToken, status, page);
      commit(SET_ORDER_MUTATION, result.results);
      commit(SET_ORDER_PAGE, page);
      commit(SET_ORDER_TOTAL, result.total);
    } catch {
      this.dispatch(`app/${SHOW_SNACKBAR_COMMON_ERROR_ACTION}`);
    }
  },
  async [GET_ORDER_BY_ID_ACTION]({ commit }, { accessToken, orderId }) {
    try {
      const result = await getOrderById(accessToken, orderId);
      commit(SET_ORDER_BY_ID_MUTATION, result);
    } catch (error) {
      this.dispatch(`app/${SHOW_SNACKBAR_COMMON_ERROR_ACTION}`);
    }
  }
};

const mutations = {
  [SET_SHOW_CANCEL_ORDER_DIALOG](state, value) {
    state.isShowCancelOrderDialog = value;
  },
  [SET_ORDER_MUTATION](state, value) {
    state.orders = value;
  },
  [SET_ORDER_BY_ID_MUTATION](state, value) {
    state.order = value;
  },
  [SET_ORDER_PAGE](state, value) {
    state.page = value;
  },
  [SET_ORDER_TOTAL](state, value) {
    state.total = value;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
