import { get } from '@/helpers/axios';

export const getAnnouncements = (accessToken) => {
  try {
    // mock return result    
    // TODO-announcement: use axios to get admin announcements
    return get('/announcements', {}, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
