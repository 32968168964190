import Vue from 'vue';
import Vuex from 'vuex';
import { confirmPayment } from '@/services/payment';
import { BANK_PROVIDERS } from '@/helpers/payment';
import { SET_SHOW_CONFIRM_PAYMENT_DIALOG } from '../_mutationTypes';
import {
  CHANGE_SNACKBAR_COLOR,
  CHANGE_SNACKBAR_MESSAGE,
  CONFIRM_PAYMENT,
  SHOW_CONFIRM_PAYMENT_DIALOG,
  SHOW_SNACKBAR,
} from '../_actionTypes';

Vue.use(Vuex);
const states = {
  isShowConfirmPaymentDialog: false,
  bankList: BANK_PROVIDERS,
};

const getters = {
  getBankList: (state) => state.bankList,
};

const actions = {
  [SHOW_CONFIRM_PAYMENT_DIALOG]({ commit }, value) {
    commit(SET_SHOW_CONFIRM_PAYMENT_DIALOG, value);
  },
  async [CONFIRM_PAYMENT]({ dispatch }, { orderId, fileId, paymentBank, accessToken}) {
    try {
      const result = await confirmPayment(orderId, fileId, paymentBank, accessToken);
      if (result) {
        dispatch(SET_SHOW_CONFIRM_PAYMENT_DIALOG, false);
        this.dispatch(`app/${SHOW_SNACKBAR}`, true);
        this.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'แจ้งชำระเงินสำเร็จ');
        this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'green');
      }

      return 1;
    } catch (e) {
      this.dispatch(`app/${SHOW_SNACKBAR}`, true);
      this.dispatch(
        `app/${CHANGE_SNACKBAR_MESSAGE}`,
        `แจ้งชำระเงินไม่สำเร็จ กรุณาลองใหม่ในภายหลัง error: ${e}`,
      );
      this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'red');
      return 0;
    }
  },
};

const mutations = {
  [SET_SHOW_CONFIRM_PAYMENT_DIALOG](state, value) {
    state.isShowConfirmPaymentDialog = value;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
