import Vue from 'vue';
import Vuex from 'vuex';

import {
  confirmQuotation,
  confirmCoupon,
} from '@/services/quotation';

import {
  CONFIRM_QUOTATION,
  CONFIRM_COUPON,
} from '@/store/_actionTypes';

Vue.use(Vuex);

const states = {};

const defineGetters = {};

const actions = {
  async [CONFIRM_QUOTATION]({}, { accessToken, order }) {
    const result = await confirmQuotation(accessToken, order);
    return result;
  },
  async [CONFIRM_COUPON]({}, { accessToken, couponCode }) {
    const result = await confirmCoupon(accessToken, couponCode);
    return result;
  },
};

const mutations = {};

export default {
  namespaced: true,
  state: states,
  getters: defineGetters,
  actions,
  mutations,
};
