import KbankIcon from '@/assets/images/bank/kbank.png';
import KbankIcon2x from '@/assets/images/bank/kbank@2x.png';
import KbankIcon3x from '@/assets/images/bank/kbank@3x.png';
import KrungthaiIcon from '@/assets/images/bank/krungthai.png';
import KrungthaiIcon2x from '@/assets/images/bank/krungthai@2x.png';
import KrungthaiIcon3x from '@/assets/images/bank/krungthai@3x.png';
import KrungthepIcon from '@/assets/images/bank/krungthep.png';
import KrungthepIcon2x from '@/assets/images/bank/krungthep@2x.png';
import KrungthepIcon3x from '@/assets/images/bank/krungthep@3x.png';
import ScbIcon from '@/assets/images/bank/scb.png';
import ScbIcon2x from '@/assets/images/bank/scb@2x.png';
import ScbIcon3x from '@/assets/images/bank/scb@3x.png';

export const BANKS = [
  {
    id: 1,
    accountName: 'บจก.นรินทร์เภสัช',
    accountBranch: 'โลตัส ชัยภูมิ',
    accountNumber: '021 1 78884 4',
    bankName: 'กสิกรไทย',
    imageSrc: KbankIcon,
    imageSrcSet: `${KbankIcon2x} 2x, ${KbankIcon3x} 3x`,
    className: 'kbank',
  }
];

export const BANK_PROVIDERS = [
  { id: 'กสิกรไทย', name: 'กสิกรไทย' },
  { id: 'กรุงเทพ', name: 'กรุงเทพ' },
  { id: 'กรุงไทย', name: 'กรุงไทย' },
  { id: 'ไทยพาณิชย์', name: 'ไทยพาณิชย์' },
];

export const getPaymentOptionTitle = (paymentMethod) => {
  switch (paymentMethod) {
    case 'transfer':
      return 'โอน/ชำระผ่านบัญชีธนาคาร';
    case 'cash':
      return 'ชำระเงินสด';
    default:
      return 'ไม่ระบุ';
  }
};

export const getBankDetail = (bankName) => BANKS.find((bank) => bank.bankName === bankName);

export default {};
