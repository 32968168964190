import Vue from 'vue';
import Vuex from 'vuex';
import { fetchRedeemItems, redeemItem } from '@/services/capsule-market';
import NotFoundProductImage from '@/assets/images/medicine/not-found-product.png';
import {
  SET_CAPSULE_MARKET_ITEMS,
  SET_REDEEM_POINT,
  SET_SHOW_DIALOG_REDEEM_SUCCESS,
} from '../_mutationTypes';
import {
  FETCH_CAPSULE_MARKET_ITEMS,
  REDEEM_ITEMS,
  SHOW_DIALOG_REDEEM_SUCCESS,
} from '../_actionTypes';

Vue.use(Vuex);

const states = {
  capsuleItem: [],
  redeemPoint: 0,
  showDialogRedeemSuccess: false,
};

const getters = {
  getCapsuleItems: (state) => state.capsuleItem,
  getRedeemPoint: (state) => state.redeemPoint,
  isShowDialogRedeemSuccess: (state) => state.showDialogRedeemSuccess,
};

const actions = {
  async [FETCH_CAPSULE_MARKET_ITEMS]({ commit }, accessToken) {
    try {
      const items = await fetchRedeemItems(accessToken);
      commit(SET_CAPSULE_MARKET_ITEMS, items.results);
    } catch (e) {
      console.log(e);
    }
  },
  async [REDEEM_ITEMS]({ commit, dispatch }, { id, accessToken, capsulePoint }) {
    try {
      const isRedeemSuccess = await redeemItem(id, accessToken);
      if (isRedeemSuccess) {
        commit(SET_REDEEM_POINT, capsulePoint);
        dispatch(SHOW_DIALOG_REDEEM_SUCCESS, true);
        setTimeout(async () => {
          window.location.reload();
        }, 1000)
      }
    } catch (e) {
      console.log(e);
    }
  },
  [SHOW_DIALOG_REDEEM_SUCCESS]({ commit }, value) {
    commit(SET_SHOW_DIALOG_REDEEM_SUCCESS, value);
  },
};

const mutations = {
  [SET_CAPSULE_MARKET_ITEMS](state, items) {
    state.capsuleItem = items;
  },
  [SET_REDEEM_POINT](state, point) {
    state.redeemPoint = point;
  },
  [SET_SHOW_DIALOG_REDEEM_SUCCESS](state, value) {
    state.showDialogRedeemSuccess = value;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
