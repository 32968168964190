<template>
  <v-snackbar
    v-model="isDisplaySnackBar"
    :class="getClassName"
    :timeout="timeout"
    :color="color"
    :multi-line="true"
    transition="slide-y-transition"
    top
  >
    {{ title }}
  </v-snackbar>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { SHOW_SNACKBAR } from '@/store/_actionTypes';

export default {
  name: 'Snackbar',
  props: {
    className: {
      type: String,
      default: () => '',
    },
    color: {
      type: String,
      default: () => undefined,
    },
    title: {
      type: String,
      default: () => '',
    },
    timeout: {
      type: String,
      default: () => '5000',
    },
  },
  computed: {
    ...mapState('app', ['isDisplaySnackBar']),
    getClassName() {
      return this.className === '' ? 'btn' : this.className;
    },
  },
  watch: {
    isDisplaySnackBar() {
      if (this.isDisplaySnackBar) {
        setTimeout(() => {
          this.hideSnackBar();
        }, this.timeout);
      }
    },
  },
  methods: {
    ...mapActions('app', [SHOW_SNACKBAR]),
    hideSnackBar() {
      this[SHOW_SNACKBAR](false);
    },
  },
}

</script>
