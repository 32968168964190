<template>
  <div class="menu">
    <div :class="getClassName">
      <div
        v-for="({ title, iconImg, isExternalLink, redirectTo, externalRedirectTo, guestRedirectTo, gtag }, key) in menuList"
        :key="key"
        class="d-flex align-center mr-4 py-2"
        @click="clickMenu({ isExternalLink, redirectTo, externalRedirectTo, guestRedirectTo, gtag })"
      >
        <slot />
        <img
          v-if="hasIconImage(iconImg)"
          class="img"
          :src="iconImg"
        >
        <span
          v-if="hasTitle(title)"
          :class="getTitleClassName(iconImg)"
        >{{ getUserLineId(title) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { REDIRECT_TO_ACTION } from '@/store/_actionTypes';

export default {
  name: 'Menu',
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    className: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapGetters('user', ['getUserInfo']),
    getClassName() {
      return this.className === '' ? 'd-flex flex-row white-text' : `${this.className} white-text`;
    },
  },
  methods: {
    ...mapActions('app', [REDIRECT_TO_ACTION]),
    getTitleClassName(iconImg) {
      return this.hasIconImage(iconImg) ? 'ml-2' : '';
    },
    hasIconImage(iconImg) {
      return iconImg !== undefined;
    },
    hasTitle(title) {
      return title !== undefined;
    },
    clickMenu({ isExternalLink, redirectTo, externalRedirectTo, guestRedirectTo, gtag }) {
      if (!redirectTo) return;
      if (guestRedirectTo && (!this.getUserInfo || this.getUserInfo.level == 0))
        redirectTo = guestRedirectTo;

      if (gtag) {
        this.$gtag.event('conversion', {'send_to': gtag});
      }

      this[REDIRECT_TO_ACTION]({ isExternalLink, redirectTo, externalRedirectTo, currentRouteName: this.$route.name, router: this.$router });
    },
    getUserLineId(title) {
      if (title !== '@narinonline') return title;
      return !this.getUserInfo || this.getUserInfo.level == 0 ? '@narinch' : '@narinonline';
    },
  },
};

</script>

<style lang="scss">
  .menu {
    cursor: pointer;

    .img {
      width: 16px;
      height: 16px;
      border-radius: 8px !important;
    }
    .white-text {
      color: #ffffff;
    }
  }
</style>
