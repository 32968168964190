<template>
  <div class="user-info d-flex align-center">
    <div
      v-if="isLoggedIn"
      class="d-flex align-center"
    >
      <v-menu
        nudge-bottom="1"
        offset-y
        open-on-hover
        class="userinfo__menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="d-flex align-center"
            v-on="on"
          >
            <Menu
              :menu-list="userMenus"
            />
            <v-icon
              dark
              small
            >
              mdi-chevron-down
            </v-icon>
          </div>
        </template>
        <v-list class="list">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :key="index"
            @click="clickMenu(item)"
          >
            <v-list-item-avatar
              width="16px"
              height="16px"
              class="mr-1 ml-n3"
            >
              <img
                class="img"
                :src="item.iconImg"
              >
            </v-list-item-avatar>
            <v-list-item-title class="list-item-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    
    <v-btn
      v-else-if="!isLoggedIn"
      rounded
      class="login-btn"
      @click="showLogInDialog"
    >
      <span class="text pl-3 pr-3">เข้าสู่ระบบ</span>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import UserLogo from '@/assets/images/customer/placeholder-profile.jpeg';

import { SHOW_LOGIN_DIALOG, REDIRECT_TO_ACTION } from '@/store/_actionTypes';

import Menu from './Menu.vue';

export default {
  name: 'UserInfo',
  components: {
    Menu,
  },
  computed: {
    ...mapState('app', ['profileMenus']),
    ...mapGetters('user', ['isLoggedIn', 'getUserInfo']),
    userMenus() {
      return [
        { title: this.getUserInfo?.fullname, iconImg: this.getUserInfo?.image?.url ? this.getUserInfo.image.url : UserLogo },
      ];
    }
  },
  methods: {
    ...mapActions('app', [SHOW_LOGIN_DIALOG, REDIRECT_TO_ACTION]),
    showLogInDialog() {
      this[SHOW_LOGIN_DIALOG](true);
    },
    clickMenu({ isExternalLink, redirectTo, externalRedirectTo }) {
      if (!redirectTo) return;
      this[REDIRECT_TO_ACTION]({ isExternalLink, redirectTo, externalRedirectTo, currentRouteName: this.$route.name, router: this.$router });
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/fonts.scss';

.user-info {
  .list {
    cursor: pointer !important;

    .img {
      width: 16px !important;
      height: 16px !important;
    }
  }

  .login-btn {
    margin-bottom: 0 !important;

    .text {
      @include kanit;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: $secondary-color-00;
    }
  }
}

.userinfo__menu {
  z-index: 10 !important;
}

.list-item-title {
  @include sarabun;
  font-size: 14px !important;
  line-height: 1.57 !important;
  color: $black-00 !important;
}
</style>
