import Vue from 'vue';
import Vuex from 'vuex';
import { uploadImage } from '@/services/upload-image';
import { SHOW_UPLOAD_PROGRESS, UPLOAD_IMAGE_ACTION } from '../_actionTypes';
import { SET_SHOW_UPLOAD_PROGRESS } from '../_mutationTypes';

Vue.use(Vuex);
const states = {
  isShowUploadProgress: false,
};

const getters = {};

const actions = {
  async [UPLOAD_IMAGE_ACTION]({ rootState }, file) {
    const { accessToken } = rootState.user;
    const form = new FormData();
    form.append('file', file);
    return uploadImage(form, accessToken);
  },
  [SHOW_UPLOAD_PROGRESS]({ commit }, value) {
    commit(SET_SHOW_UPLOAD_PROGRESS, value);
  },
};

const mutations = {
  [SET_SHOW_UPLOAD_PROGRESS](state, value) {
    state.isShowUploadProgress = value;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
