import { get, postMultiPath } from '@/helpers/axios';

export const getImage = async (id, accessToken) => {
  if (!id) return null;
  return await get(`/file/${id}/url`, {}, accessToken);
}

export const uploadImage = (formData, accessToken) =>
  postMultiPath('/file/upload', formData, accessToken);

export default {};
