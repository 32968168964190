import { get } from '@/helpers/axios';

export const cancelOrder = (orderId) => !!orderId;

export const getOrderById = (accessToken, orderId) => {
  try {
    const result = get(`/sales/${orderId}`, {}, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getOrders = (accessToken, status = '', page = 1, limit = 5) => {
  try {
    let query = { page, limit };
    if (!!status) query = { ...query, status };

    const params = new URLSearchParams(query);
    const result = get(`/sales?${params}`, {}, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
