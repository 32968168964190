export const SET_APP_BAR_TYPE = 'SET_APP_BAR_TYPE';

export const SET_USER_INFO_MUTATION = 'SET_USER_INFO_MUTATION';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_LOGIN_ERROR_MESSAGE = 'SET_LOGIN_ERROR_MESSAGE';

export const SET_MOBILE_SCREEN_SIZE = 'SET_MOBILE_SCREEN_SIZE';

export const SET_SHOW_LOGIN_DIALOG = 'SET_SHOW_LOGIN_DIALOG';

export const SET_SHOW_SNACKBAR = 'SET_SHOW_SNACKBAR';

export const SET_CURRENT_SELECTED_TAB = 'SET_CURRENT_SELECTED_TAB';

// app
export const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE';
export const SET_SNACKBAR_COLOR = 'SET_SNACKBAR_COLOR';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

// user
export const SET_SHIPPINGS = 'SET_SHIPPINGS';
export const SET_FAVORITES = 'SET_FAVORITES';
export const SET_CERT_FILE = 'SET_CERT_FILE';
export const SET_PERMISSION_FILE = 'SET_PERMISSION_FILE';

// quotation
export const SET_DELIVERY_LOCATION = 'SET_DELIVERY_LOCATION';
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_PAYMENT_OPTION = 'SET_PAYMENT_OPTION';
export const SET_PICKUP_LOCATION = 'SET_PICKUP_LOCATION';

// confirm-payment
export const SET_SHOW_CONFIRM_PAYMENT_DIALOG = 'SHOW_CONFIRM_PAYMENT_DIALOG';

// CapsuleMarket
export const SET_CAPSULE_MARKET_ITEMS = 'SET_CAPSULE_MARKET_ITEMS';
export const SET_REDEEM_POINT = 'SET_REDEEM_POINT';
export const SET_SHOW_DIALOG_REDEEM_SUCCESS = 'SET_SHOW_DIALOG_REDEEM_SUCCESS';

// order
export const SET_SHOW_CANCEL_ORDER_DIALOG = 'SET_SHOW_CANCEL_ORDER_DIALOG';
export const SET_ORDER_MUTATION = 'SET_ORDER_MUTATION';
export const SET_ORDER_BY_ID_MUTATION = 'SET_ORDER_BY_ID_MUTATION';
export const SET_ORDER_PAGE = 'SET_ORDER_PAGE';
export const SET_ORDER_TOTAL = 'SET_ORDER_TOTAL';

// request-product
export const SET_SHOW_REQUEST_PRODUCT_DIALOG = 'SET_SHOW_REQUEST_PRODUCT_DIALOG';
export const SET_REQUEST_PRODUCT = 'SET_REQUEST_PRODUCT';
export const SET_REQUEST_PRODUCT_TOTAL = 'SET_REQUEST_PRODUCT_TOTAL';
export const SET_REQUEST_PRODUCT_PAGE = 'SET_REQUEST_PRODUCT_PAGE';

// upload-image
export const SET_SHOW_UPLOAD_PROGRESS = 'SET_SHOW_UPLOAD_PROGRESS';

// shop
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORIY_FILTER = 'SET_CATEGORIY_FILTER';
export const SET_MIN_PRICE = 'SET_MIN_PRICE';
export const SET_MAX_PRICE = 'SET_MAX_PRICE';
export const SET_SORT = 'SET_SORT';
export const SET_PAGE = 'SET_PAGE';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_KEYWORD = 'SET_KEYWORD';
export const SET_LOADING = 'SET_LOADING';

// cart
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const SET_CAPSULE_CONFIG = 'SET_CAPSULE_CONFIG';

// product
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRODUCT_RELATED = 'SET_PRODUCT_RELATED';
export const SET_RECOMMENDATION = 'SET_RECOMMENDATION';

// wishlist
export const SET_WISHLIST_PRODUCT = 'SET_WISHLIST_PRODUCT';
export const UPDATE_WISHLIST_PRODUCT = 'UPDATE_WISHLIST_PRODUCT';

//announcement
export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';

export default {};
