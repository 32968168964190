import { post } from '@/helpers/axios';

export const login = (email, password) => {
  try {
    const body = {
      email,
      password,
    };
    const result = post('/auth/login', body);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
