import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/pages/main/router';
import {
  CURRENT_SELECTED_TAB,
  NAVIGATE_USER_PROFILE,
} from '@/store/_actionTypes';
import { SET_CURRENT_SELECTED_TAB } from '@/store/_mutationTypes';

Vue.use(Vuex);
const states = {
  currentTab: 0,
};

const getters = {
  getCurrentTab: (state) => state.currentTab,
};

const actions = {
  [NAVIGATE_USER_PROFILE]({}, currentTab) {
    switch (currentTab) {
      case 0:
        router.push({ name: 'UserInfo' });
        break;
      case 1:
        router.push({ name: 'OrderHistory' });
        break;
      case 2:
        router.push({ name: 'RequestProduct' });
        break;
      case 3:
        router.push({ name: 'Wishlist' });
        break;
      case 4:
        router.push({ name: 'Referral' });
        break;
      default:
        break;
    }
  },
  [CURRENT_SELECTED_TAB]({ commit }, pathName) {
    switch (pathName) {
      case 'UserInfo':
        commit(SET_CURRENT_SELECTED_TAB, 0);
        break;
      case 'OrderHistory':
        commit(SET_CURRENT_SELECTED_TAB, 1);
        break;
      case 'OrderHistoryDetails':
        commit(SET_CURRENT_SELECTED_TAB, 1);
        break;
      case 'RequestProduct':
        commit(SET_CURRENT_SELECTED_TAB, 2);
        break;
      case 'RequestProductOffer':
        commit(SET_CURRENT_SELECTED_TAB, 2);
        break;
      case 'Wishlist':
        commit(SET_CURRENT_SELECTED_TAB, 3);
        break;
      case 'Referral':
        commit(SET_CURRENT_SELECTED_TAB, 4);
        break;
      default:
        break;
    }
  },
};

const mutations = {
  [SET_CURRENT_SELECTED_TAB](state, currentTab) {
    state.currentTab = currentTab;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
