import Vue from 'vue';
import Vuex from 'vuex';

import {
  getCategories,
  getProducts,
  getRecommendation
} from '@/services/product';

import {
  GET_CATEGORIES,
  GET_PRODUCTS,
  UPDATE_CATEGORY_FILTER,
  UPDATE_MIN_PRICE,
  UPDATE_MAX_PRICE,
  UPDATE_SORT,
  UPDATE_PAGE,
  UPDATE_KEYWORD,
  GET_PRODUCTS_SUGGESTION,
  GET_RECOMMENDATION
} from '@/store/_actionTypes';

import {
  SET_CATEGORIES,
  SET_CATEGORIY_FILTER,
  SET_MIN_PRICE,
  SET_MAX_PRICE,
  SET_SORT,
  SET_PAGE,
  SET_TOTAL,
  SET_PRODUCTS,
  SET_KEYWORD,
  SET_LOADING,
  SET_RECOMMENDATION
} from '@/store/_mutationTypes';

Vue.use(Vuex);

let source;


const states = {
  keyword: '',
  categories: [],
  categoryFilter: [],
  sort: { id: 'latest', label: 'สินค้ามาใหม่' },
  minPrice: undefined,
  maxPrice: undefined,
  page: 1,
  total: 1,
  products: [],
  recommendation: [],
  loading: true,
};

const getters = {
  getCategories: ({ categories }) => {
    return categories.map(category => {
      const children = category.children.map(child => {
        return { id: child.id, name: child.name };
      });

      return {
        id: category.id,
        name: category.name,
        children,
      }
    });
  },
};

const actions = {
  async [GET_CATEGORIES]({ commit }) {
    const result = await getCategories();
    const parentCategories = result.filter(item => item.parentId === null);
    commit(SET_CATEGORIES, parentCategories);
  },
  async [GET_PRODUCTS_SUGGESTION]({ }, { keyword, accessToken }) {
    const products = await getProducts(
      keyword,
      'latest',
      [],
      null,
      null,
      1,
      5,
      accessToken
    );
    return products.results;
  },
  async [GET_PRODUCTS]({ commit }, { keyword, sort, categoryFilter, minPrice, maxPrice, page, limit, accessToken }) {
    commit(SET_LOADING, true);
    const categoryFilterWithoutPrice = categoryFilter.filter(item => !(`${item}`.includes("price-")));
    const products = await getProducts(keyword, sort, categoryFilterWithoutPrice, minPrice, maxPrice, page, limit, accessToken);
    commit(SET_PRODUCTS, products.results.filter(item => item.visibility !== -1));
    commit(SET_PAGE, products.page);
    commit(SET_TOTAL, products.total);
    commit(SET_LOADING, false);
  },
  async [GET_RECOMMENDATION]({ commit }, { accessToken }) {
    const data = await getRecommendation(1, 20, accessToken);
    const { results } = data;
    const setResult = [];
    let perSlide = 4;
    if (window.innerWidth < 960) {
      perSlide = 2;
    }

    let tmpArray = [];
    for (let i = 0; i < results.length; i+=1 ) {
      if (tmpArray.length >= perSlide) {
        setResult.push(tmpArray);
        tmpArray = [];        
      }

      tmpArray.push(results[i].product);
    }

    if (tmpArray.length > 0) {
      setResult.push(tmpArray);
    }

    console.log(setResult);

    commit(SET_RECOMMENDATION, setResult);
  },
  [UPDATE_CATEGORY_FILTER]({ commit }, categoryFilter) {
    commit(SET_CATEGORIY_FILTER, categoryFilter);
  },
  [UPDATE_SORT]({ commit }, sort) {
    commit(SET_SORT, sort);
  },
  [UPDATE_MIN_PRICE]({ commit }, price) {
    commit(SET_MIN_PRICE, price);
  },
  [UPDATE_MAX_PRICE]({ commit }, price) {
    commit(SET_MAX_PRICE, price);
  },
  [UPDATE_PAGE]({ commit }, page) {
    commit(SET_PAGE, page);
  },
  [UPDATE_KEYWORD]({ commit }, keyword) {
    commit(SET_KEYWORD, keyword);
  },
};

const mutations = {
  [SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [SET_CATEGORIY_FILTER](state, categoryFilter) {
    state.categoryFilter = categoryFilter;
  },
  [SET_MIN_PRICE](state, minPrice) {
    state.minPrice = minPrice;
  },
  [SET_MAX_PRICE](state, maxPrice) {
    state.maxPrice = maxPrice;
  },
  [SET_SORT](state, sort) {
    state.sort = sort;
  },
  [SET_PAGE](state, page) {
    state.page = page;
  },
  [SET_TOTAL](state, total) {
    state.total = total;
  },
  [SET_PRODUCTS](state, products) {
    state.products = products;
  },
  [SET_KEYWORD](state, keyword) {
    state.keyword = keyword;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_RECOMMENDATION](state, products) {
    state.recommendation = products;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
