<template>
  <div class="app-bar-b">
    <MobileVersion
      :contact-menus="contactMenus"
      :page-menus="publicPageMenus"
      :drawer="drawer"
      @setDrawer="setDrawer"
    />

    <v-app-bar
      app
      hide-on-scroll
      height="120"
      absolute
      scroll-target="#scrolling-techniques-4"
    >
      <v-app-bar-nav-icon
        v-if="isMobileScreenSize"
        color="white"
        @click="setDrawer(!drawer)"
      />
      <div 
        v-if="!isMobileScreenSize"
        class="d-flex flex-column container mt-8"
      >
        <div class="d-flex justify-space-between mb-3 pl-4 pr-4 top">
          <Menu
            :menu-list="contactMenus"
            :class-name="'d-flex flex-row text-with-icon mr-10'"
          />
          <div class="d-flex">
            <v-img
              class="mr-2"
              :src="mapPinIcon"
              width="20"
              height="20"
            />
            <span class="text">273/35 ถนนยุติธรรม ตำบลในเมือง อำเภอเมือง จังหวัดชัยภูมิ 36000</span>
            <v-divider
              class="mr-4 ml-4"
              vertical
              color="white"
            />
            <span class="text">จ. - ส. 08:00 น. - 18:00 น.</span>
          </div>
        </div>
        <v-divider class="divider" />
        <div 
          class="d-flex justify-space-between mt-4 pl-4 pr-4"
        >
          <TabMenu :menus="publicPageMenus" />
          <UserInfo />
        </div>
      </div>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-4"
      class="overflow-y-auto"
      max-height="120"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import mapPinIcon from '@/assets/images/logo/map-pin-circle.svg';

import Menu from './Menu.vue';
import MobileVersion from './MobileVersion.vue';
import UserInfo from './UserInfo.vue';
import TabMenu from './TabMenu.vue';

export default {
  name: 'BVersion',
  components: {
    Menu,
    MobileVersion,
    UserInfo,
    TabMenu,
  },
  data: () => ({
    drawer: false,
    mapPinIcon
  }),
  computed: {
    ...mapState('app', ['contactMenus', 'isMobileScreenSize', 'publicPageMenus']),
  },
  methods: {
    setDrawer(drawer) {
      this.drawer = drawer;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/fonts.scss';
@import '@/styles/variables.scss';

.app-bar-b {
  .v-sheet.v-toolbar {
    margin-top: 24px !important;
  }

  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: transparent !important;
    border: none !important;
  }

  .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .container {
    width: 100%;
  }

  .top {
    width: 100%;
  }

  .text {
    @include sarabun;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: $white-00;

    &-with-icon {
      @include muli;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  .divider {
    border: solid 1px rgba(255, 255, 255, 0.5) !important;
  }
}
</style>
