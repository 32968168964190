import { create } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { LOCAL_STORAGE_ACCESS_TOKEN } from '@/store/modules/user.store';
import { LOCAL_STORAGE_CART_ITEMS } from '@/store/modules/cart.store';

const instance = create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000,
});

instance.interceptors.response.use(
  (response) => camelCaseKeys(response.data, { deep: true }),
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_CART_ITEMS);
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    } else {
      throw error;
    }
  }
);

const createAccessToken = (accessToken) => ({
  Authorization: `Bearer ${accessToken}`,
});

const multiPathHeader  = {
  'Content-Type': 'multipart/form-data',
};

const convertBody = (body = {}) => snakeCaseKeys(body, { deep: true });

export const post = (path, body, accessToken) =>
  instance.post(
    path,
    {
      ...convertBody(body),
    },
    {
      headers: createAccessToken(accessToken),
    },
  );

export const postWithoutToken = (path, body) =>
  instance.post(path, { ...convertBody(body) });

export const postMultiPath = (path, formData, accessToken) =>
  instance.post(path, formData, {
    headers: { ...createAccessToken(accessToken), ...multiPathHeader },
  });

export const postMultiPathWithoutToken = (path, formData) =>
  instance.post(path, formData, { headers: { ...multiPathHeader }});

export const getWithoutToken = (path, body, cancelToken = null) => {
  if (cancelToken) {
    return instance.get(path, {
      ...convertBody(body),
      cancelToken,
    });
  } else {
    return instance.get(path, {
      ...convertBody(body),
    });
  }
}

export const get = (path, body, accessToken, cancelToken = null) => {
  if (cancelToken) {
    return instance.get(path, {
      ...convertBody(body),
      cancelToken,
      headers: createAccessToken(accessToken),
    });
  } else {
    return instance.get(path, {
      ...convertBody(body),
      headers: createAccessToken(accessToken),
    });
  }
}


export const patch = (path, body, accessToken) =>
  instance.patch(path, {
    ...convertBody(body),
    headers: createAccessToken(accessToken),
  });

export const put = (path, body, accessToken) =>
  instance.put(
    path,
    {
      ...convertBody(body),
    },
    {
      headers: createAccessToken(accessToken),
    },
  );

export const remove = (path, body, accessToken) => {
  return instance.delete(path, {
    ...convertBody(body),
    headers: createAccessToken(accessToken),
  });
}

export default {};
