import Vue from 'vue';
import Vuex from 'vuex';
import { uploadImage } from '@/services/upload-image';
import {
  getRequestProduct,
  createRequestProduct,
  rejectedRequestProduct,
  acceptedRequestProduct,
} from '@/services/product';
import {
  SET_SHOW_REQUEST_PRODUCT_DIALOG,
  SET_REQUEST_PRODUCT,
  SET_REQUEST_PRODUCT_TOTAL,
  SET_REQUEST_PRODUCT_PAGE,
} from '../_mutationTypes';
import {
  SHOW_REQUEST_PRODUCT_DIALOG,
  REQUEST_PRODUCT,
  GET_REQUEST_PRODUCT,
  REJECTED_REQUEST_PRODUCT,
  ACCEPTED_REQUEST_PRODUCT,
} from '../_actionTypes';

Vue.use(Vuex);

const states = {
  showRequestProductDialog: false,
  requestProducts: [],
  total: 1,
  page: 1,
};

const getters = {
  isShowRequestProductDialog: (state) => state.showRequestProductDialog,
};

const actions = {
  async [GET_REQUEST_PRODUCT]({ commit }, { accessToken, status, page }) {
    const result = await getRequestProduct(accessToken, status, page);
    commit(SET_REQUEST_PRODUCT_PAGE, page);
    commit(SET_REQUEST_PRODUCT_TOTAL, result.total);
    commit(SET_REQUEST_PRODUCT, result.results);
  },
  [SHOW_REQUEST_PRODUCT_DIALOG]({ commit }, value) {
    commit(SET_SHOW_REQUEST_PRODUCT_DIALOG, value);
  },
  async [REQUEST_PRODUCT]({}, { accessToken, payload }) {
    try {
      const file = payload.productImage;
      const form = new FormData();
      form.append('file', file);
      const result = await uploadImage(form, accessToken);
      const modPayload = {
        product_name: payload.productName,
        product_image_id: result.id,
        product_company: payload.manufacturer,
        request_qty: payload.qty,
        request_unit: payload.unit,
        description: payload.description
      }
      await createRequestProduct(accessToken, modPayload);
    } catch (e) {
      console.log(e);
    }
  },
  async [REJECTED_REQUEST_PRODUCT]({}, { accessToken, payload }) {
    try {
      await rejectedRequestProduct(accessToken, payload);
    } catch (e) {
      console.log(e);
    }
  },
  async [ACCEPTED_REQUEST_PRODUCT]({}, { accessToken, payload }) {
    try {
      await acceptedRequestProduct(accessToken, payload);
    } catch (e) {
      console.log(e);
    }
  },
};

const mutations = {
  [SET_SHOW_REQUEST_PRODUCT_DIALOG](state, value) {
    state.showRequestProductDialog = value;
  },
  [SET_REQUEST_PRODUCT](state, value) {
    state.requestProducts = value;
  },
  [SET_REQUEST_PRODUCT_PAGE](state, value) {
    state.page = value;
  },
  [SET_REQUEST_PRODUCT_TOTAL](state, value) {
    state.total = value;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
