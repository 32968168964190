import Vue from 'vue';
import Vuex from 'vuex';

import {
  INIT_CART,
  ADD_ITEM,
  UPDATE_QTY,
  UPDATE_SELECTED,
  REMOVE_ITEM,
  CLEAR_ITEM,
  SHOW_SNACKBAR,
  CHANGE_SNACKBAR_COLOR,
  CHANGE_SNACKBAR_MESSAGE
} from '@/store/_actionTypes';

import {
  SET_CART_ITEMS,
  SET_CAPSULE_CONFIG,
} from '@/store/_mutationTypes';

import { formatNumber } from "@/helpers/number";
import {
  isFreeGiftPromotionValid,
  calculateFreeBuyFreeGift,
  applyDiscountPromotion,
  applyCapsulePromotion,
  getPriceRate
} from "@/helpers/promotion";
import {
  getProduct,
  getCapsuleConfig,
} from '@/services/product';

import {
  getCart,
  addProductToCart,
  removeProductFromCart,
  setProductInCart
} from '@/services/user/user';

import NotFoundProductImage from '@/assets/images/medicine/not-found-product.png';

Vue.use(Vuex);

export const LOCAL_STORAGE_CART_ITEMS = 'cart_items';
import { LOCAL_STORAGE_ACCESS_TOKEN } from './user.store';

const states = {
  cartItems: [],
  capsuleConfig: 25,
};

const getters = {
  getNormalProduct: ({ cartItems }) => {
    return cartItems.filter(item => !item.isCapsule);
  },
  getNormalProductSelected: ({ cartItems }) => {
    return cartItems.filter(item => !item.isCapsule && item.selected);
  },
  getCapsuleProduct: ({ cartItems }) => {
    return cartItems.filter(item => item.isCapsule);
  },
  getCapsuleProductSelected: ({ cartItems }) => {
    return cartItems.filter(item => item.isCapsule && item.selected);
  },
  getSummaryPrice: ({ cartItems }) => {
    return cartItems
      .filter(item => item.selected)
      .reduce((accumulator, item) => {
        return accumulator + (applyDiscountPromotion(item, getPriceRate(item))) * item.qty
      }, 0)
  },
  getTotalPrice: ({ cartItems }) => {
    return `฿${formatNumber(
      cartItems
        .filter(item => item.selected)
        .reduce((accumulator, item) => {
          return accumulator + (applyDiscountPromotion(item, getPriceRate(item))) * item.qty
        }, 0)
    )}`;
  },
  getTotalPriceWithoutDiscount: ({ cartItems }) => {
    return `฿${formatNumber(
      cartItems
        .filter(item => item.selected)
        .reduce((accumulator, item) => {
          return accumulator + (item.qty * getPriceRate(item))
        }, 0)
    )}`;
  },
  getTotalCapsule: ({ cartItems }) => {
    return `${formatNumber(
      Math.floor(
      cartItems
      .filter(item => item.selected)
      .reduce((accumulator, item) => {
        if (!item.capsule)
          return accumulator + ((applyDiscountPromotion(item, getPriceRate(item))) * item.qty) / states.capsuleConfig;

        return accumulator + applyCapsulePromotion(item, item.capsule, item.qty);
      }, 0))
    , 0)} Capsule Points`;
  },
  getTotalCapsuleAsNumber: ({ cartItems }) => {
    return `${
      Math.floor(
      cartItems
      .filter(item => item.selected)
      .reduce((accumulator, item) => {
        if (!item.capsule)
          return accumulator + ((applyDiscountPromotion(item, getPriceRate(item))) * item.qty) / states.capsuleConfig;

        return accumulator + applyCapsulePromotion(item, item.capsule, item.qty);
      }, 0))
    }`;
  },
  getTotalShippingFee: ({ cartItems }) => {
    const totalShippingFee = cartItems
      .filter(item => item.selected)
      .reduce((accumulator, item) => {
        const freeGift = isFreeGiftPromotionValid(item) ? calculateFreeBuyFreeGift(item.promotionFreeBuy, item.promotionFreeGift, item.qty) : 0;
        const totalShippingFee = (item.qty + parseInt(freeGift)) * item.deliveryFee;
        return accumulator + totalShippingFee;
      }, 0);

    return totalShippingFee;
  },
  countProduct: ({ cartItems }) => {
    return(cartItems.reduce((accumulator, item) => {
      return accumulator + 1;
    }, 0));
  },
};

const actions = {
  [INIT_CART]({ commit, rootState }) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    (async () => {
      try {
        const capsuleConfig = await getCapsuleConfig(accessToken);
        commit(SET_CAPSULE_CONFIG, capsuleConfig.moneyAmount || 0);
      } catch (e) {
        console.error(e.message);
      }
    })()

    if (!!accessToken) {
      (async () => {
        try {
          const cartItemUpdated = [];
          const cart = await getCart(accessToken);
          const { products, capsuleProducts } = cart;
          // const userLevel = rootState.user.userInfo.level;


          for (let i = 0; i < products.length; i+=1)
          {
            const { product } = products[i];

            // product.priceRates = product.priceRates.filter(elm => elm.level === userLevel);

            cartItemUpdated.push({
              ...product,
              basketId: products[i].id,
              qty: products[i].qty,
              image: product.images.length > 0 ? product.images[0].url : NotFoundProductImage,
              selected: true
            });
          }

          for (let i = 0; i < capsuleProducts.length; i+=1)
          {
            cartItemUpdated.push({
              ...capsuleProducts[i].capsuleProduct,
              qty: capsuleProducts[i].qty, isCapsule: true,
              image: capsuleProducts[i].capsuleProduct.images.length > 0 ? capsuleProducts[i].capsuleProduct.images[0].url : NotFoundProductImage
            });
          }

          commit(SET_CART_ITEMS, cartItemUpdated)
        } catch (e) {
          console.log(e);
          this.dispatch(`app/${SHOW_SNACKBAR}`, true);
          this.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'ไม่สามารถดึงข้อมูลตะกร้าได้ โปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ต');
          this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'red');
        }
      })()
    }
  },
  [ADD_ITEM]({ state, commit, dispatch }, { qty, product }) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    if (!!accessToken) {
        (async () => {
          try {
            const response = await addProductToCart(accessToken, product.id, qty);

            const {cartItems} = state;
            const item = cartItems.find(elm => elm.id === product.id && !elm.isCapsule);

            Vue.$gtag.event('conversion', {'send_to': 'AW-966302140/ROyQCLzKlIQDELyz4swD', 'transaction_id': ''});

            if (item) {
              item.qty += qty;
              commit(SET_CART_ITEMS, [...cartItems]);
            } else {
              const newItem = {
                basketId: response.id,
                ...product,
                qty
              }
              commit(SET_CART_ITEMS, [...cartItems, newItem]);
            }
          } catch (e) {
            dispatch(`app/${SHOW_SNACKBAR}`, true, {root: true});
            dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'ไม่สามารถเอาของใส่ตะกร้าได้ โปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ต', {root: true});
            dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'red', {root: true});
          }

        })()
    } else {
      dispatch(`app/${SHOW_SNACKBAR}`, true, {root: true});
      dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'โปรดเข้าสู่ระบบก่อนเลือกซื้อสินค้า', {root: true});
      dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'red', {root: true});
    }
  },
  [UPDATE_QTY]({ state, commit }, { qty, product }) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    const {cartItems} = state;
    const item = cartItems.find(elm => elm.id === product.id);
    if (item && qty && !!accessToken) {
      item.qty = qty;
      (async () => {
        try {
          await setProductInCart(accessToken, item.basketId, qty);
        } catch (e) {
          this.dispatch(`app/${SHOW_SNACKBAR}`, true);
          this.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'ไม่สามารถแก้ไขตะกร้าได้ โปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ต');
          this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'red');
        }
      })()
    }
  },
  [UPDATE_SELECTED]({ commit }, { selected, productIds }) {
    const cartItems = localStorage.getItem(LOCAL_STORAGE_CART_ITEMS);
    const newCartItems = JSON.parse(cartItems).map(item => {
      if (productIds.includes(item.id))
          item.selected = selected;

      return item;
    });

    localStorage.setItem(LOCAL_STORAGE_CART_ITEMS, JSON.stringify(newCartItems));
    commit(SET_CART_ITEMS, newCartItems);
  },
  [REMOVE_ITEM]({ state, commit }, product) {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    if (!!accessToken) {
      (async () => {
        try {
          await removeProductFromCart(accessToken, product.basketId);
        } catch (e) {
          this.dispatch(`app/${SHOW_SNACKBAR}`, true);
          this.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'ไม่สามารถแก้ไขตะกร้าได้ โปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ต');
          this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'red');
        }
      })()

      const newCartItems = state.cartItems.filter(item => item.id !== product.id);
      commit(SET_CART_ITEMS, newCartItems);
    }
  },
  [CLEAR_ITEM]({ commit }) {
    const newCartItems = [];
    localStorage.setItem(LOCAL_STORAGE_CART_ITEMS, JSON.stringify(newCartItems));
    commit(SET_CART_ITEMS, newCartItems);
  }
};

const mutations = {
  [SET_CART_ITEMS](state, cartItems) {
    state.cartItems = cartItems;
  },
  [SET_CAPSULE_CONFIG](state, capsuleConfig) {
    state.capsuleConfig = capsuleConfig;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
