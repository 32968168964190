<template>
  <AVersion v-if="getAppBarType === APP_BAR_A_VERSION" />
  <BVersion v-else-if="getAppBarType === APP_BAR_B_VERSION" />
</template>

<script>
import { mapGetters } from 'vuex';

import {
  APP_BAR_A_VERSION,
  APP_BAR_B_VERSION,
} from '@/store/modules/app.store';

import AVersion from './components/AVersion.vue';
import BVersion from './components/BVersion.vue';

export default {
  name: 'AppBar',
  components: {
    AVersion,
    BVersion,
  },
  data: () => ({
    APP_BAR_A_VERSION,
    APP_BAR_B_VERSION,
  }),
  computed: {
    ...mapGetters('app', ['getAppBarType']),
  },
};
</script>
