import questionIcon from '@/assets/images/logo/question.png';

export const PICKUP_LOCATIONS = [
  {
    id: 1,
    value: 'general-provider',
    title: 'จัดส่งสินค้าผ่านขนส่งเอกชน',
    iconImage: questionIcon,
    description: ['- จัดส่งสินค้าผ่านขนส่งเอกชน Kerry Express , Nim Express , SCG Express, ไปรษณีย์ไทย'],
  },
  {
    id: 2,
    value: 'shop-provider',
    title: 'รับสินค้าผ่านรถขนส่งของทางร้าน',
    description: [
      // eslint-disable-next-line no-tabs
      '- วันจันทร์: ในเมืองและในเขตอำเภอเมือง', // eslint-disable-next-line no-tabs
      '- วันอังคาร: แก้งคร้อ-คอนสวรรค์-บ้านแท่น', // eslint-disable-next-line no-tabs
      '- วันพุธ: บ้านเขว้า-หนองบัวแดง-ภักดีชุมพล', // eslint-disable-next-line no-tabs
      '- วันพฤหัสฯ: ภูเขียว-เกษตรสมบูรณ์-คอนสาร', // eslint-disable-next-line no-tabs
      '- วันศุกร์: จัตุรัส-เนินสง่า-บำเหน็จณรงค์', // eslint-disable-next-line no-tabs
      '- วันเสาร์: หนองบัวระเหว-ซับใหญ่-เทพสถิต',
    ],
  },
  {
    id: 3,
    value: 'chaiyapoom-district',
    title: 'รับสินค้าภายในเขตเทศบาลเมืองชัยภูมิ',
    description: ['- ส่งฟรี รัศมีจากสาขาตลาดสดเทศบาล 1 ไม่เกิน 5 กิโลเมตร'],
  },
  {
    id: 4,
    value: 'branch_2',
    title: 'รับสินค้าที่บริษัทนรินทร์เภสัช จำกัด สาขาเยื้องประปาชัยภูมิ',
    description: [
      '- เวลาทำการ วันจันทร์ - วันเสาร์ เวลา 08.00 น. - 18.00 น. 291 ถ.ชัยภูมิ-สีคิ้ว ต.ในเมือง อ.เมือง จ.ชัยภูมิ 36000' // eslint-disable-next-line no-tabs
    ],
  },
  {
    id: 5,
    value: 'branch_1',
    title: 'รับสินค้าที่ บริษัทนรินทร์เภสัช จำกัด สาขา สาขาเทศบาล 1',
    description: [
      '- เวลาทำการ วันจันทร์ - วันเสาร์ เวลา 08.00 น. - 18.00 น. 237/35 ถ.ยุติธรรม ต.ในเมือง อ.เมือง จ.ชัยภูมิ 36000' // eslint-disable-next-line no-tabs
    ],
  }
];

export const getPickupLocationById = (id) => PICKUP_LOCATIONS.find((location) => location.id === id);

export default {};
