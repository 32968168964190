import Vue from 'vue';
import Vuex from 'vuex';
import {
    GET_NOTIFICATION,
} from '@/store/_actionTypes';
import { 
    SET_NOTIFICATION,
} from '@/store/_mutationTypes';

Vue.use(Vuex);

const states = {
    notification: [],
};

const getters = { };

const actions = {
    async [GET_NOTIFICATION]({ commit }, accessToken) {
        const notification = await getNotification(accessToken);
        commit(SET_NOTIFICATION, notification);
    },
};

const mutations = {
    [SET_NOTIFICATION](state, notification) {
        state.notification = notification;
    },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
