import {
  get,
  post,
  postWithoutToken,
  put,
  postMultiPathWithoutToken,
  remove
} from '@/helpers/axios';

export const getUserInformation = (accessToken) => {
  try {
    const result = get('/users/me', {}, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const confirmUser = (token) => {
  try {
    const result = postWithoutToken('/auth/confirm', token);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUserInformation = (accessToken, userInformation) => {
  try {
    const result = put('/users/me', userInformation, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateShipping = (accessToken, shippingId, shipping) => {
  try {
    const result = put(`/shippings/${shippingId}`, shipping, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateDefaultShipping = (accessToken, shippingId) => {
  try {
    const result = post(`/shippings/${shippingId}/actions/set_default`, {}, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createShipping = (accessToken, shipping, userId) => {
  try {
    const result = post(`/users/${userId}/shipping`, shipping, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createUser = (userInformation) => {
  try {
    const result = postMultiPathWithoutToken('/users', userInformation);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCart = (accessToken) => {
  try {
    const result = get('/baskets', {}, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addProductToCart = (accessToken, productId, productQty) => {
  try {
    const result = post(`/baskets`, {
      product_id: productId,
      qty: productQty
    }, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setProductInCart = (accessToken, basketId, productQty) => {
  try {
    const result = put(`/baskets/${basketId}`, {
      qty: productQty
    }, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const removeProductFromCart = (accessToken, basketId) => {
  try {
    const result = remove(`/baskets/${basketId}`, {}, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
