import { get, post, getWithoutToken } from '@/helpers/axios';

// TODO-capsule-market: Add axios
export const redeemItem = (productId, accessToken) => {
  try {
    const result = post(`/capsule/product/user`, { capsule_product_id: productId, qty: 1 }, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchRedeemItems = (accessToken) => {
  try {
    const result = get(`/capsule/product?page=1&limit=100`, {}, accessToken);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
