import BillIcon from "@/assets/images/order-history-details/bill.png";
import BillIconSecond from "@/assets/images/order-history-details/bill@2x.png";
import BillIconThird from "@/assets/images/order-history-details/bill@3x.png";
import SaveMoneyIcon from "@/assets/images/order-history-details/save-money.png";
import SaveMoneyIconSecond from "@/assets/images/order-history-details/save-money@2x.png";
import SaveMoneyIconThird from "@/assets/images/order-history-details/save-money@3x.png";
import ShippedIcon from "@/assets/images/order-history-details/shipped.png";
import ShippedIconSecond from "@/assets/images/order-history-details/shipped@2x.png";
import ShippedIconThird from "@/assets/images/order-history-details/shipped@3x.png";
import ShoppingBagIcon from "@/assets/images/order-history-details/shopping-bag.png";
import ShoppingBagIconSecond from "@/assets/images/order-history-details/shopping-bag@2x.png";
import ShoppingBagIconThird from "@/assets/images/order-history-details/shopping-bag@3x.png";
import InvestIcon from "@/assets/images/order-history-details/invest.png";
import InvestIconSecond from "@/assets/images/order-history-details/invest@2x.png";
import InvestIconThird from "@/assets/images/order-history-details/invest@3x.png";

export const ORDER_LOGISTIC_STEPS = [
  {
    imageSrc: BillIcon,
    step: "1",
    title: "ได้รับคำสั่งซื้อแล้ว รอการชำระเงิน",
    imageSrcSet: `${BillIconSecond} 2x, ${BillIconThird} 3x`,
  },
  {
    imageSrc: SaveMoneyIcon,
    step: "2",
    title: "ชำระเงินแล้ว กำลังตรวจสอบ",
    imageSrcSet: `${SaveMoneyIconSecond} 2x, ${SaveMoneyIconThird} 3x`,
  },
  {
    imageSrc: ShippedIcon,
    step: "3",
    title: "จัดส่งพัสดุเรียบร้อยแล้ว",
    imageSrcSet: `${ShippedIconSecond} 2x, ${ShippedIconThird} 3x`,
  },
];

export const BANK_STEPS = [
  {
    imageSrc: BillIcon,
    step: "1",
    title: "ได้รับคำสั่งซื้อแล้ว รอการชำระเงิน",
    imageSrcSet: `${BillIconSecond} 2x, ${BillIconThird} 3x`,
  },
  {
    imageSrc: SaveMoneyIcon,
    step: "2",
    title: "ชำระเงินแล้ว กำลังตรวจสอบ",
    imageSrcSet: `${SaveMoneyIconSecond} 2x, ${SaveMoneyIconThird} 3x`,
  },
  {
    imageSrc: InvestIcon,
    step: "3",
    title: "ชำระเงินและรับสินค้าแล้ว",
    imageSrcSet: `${InvestIconSecond} 2x, ${InvestIconThird} 3x`,
  },
];

export const CASH_STEPS = [
  {
    imageSrc: ShoppingBagIcon,
    step: "1",
    title: "กำลังเตรียมสินค้า",
    imageSrcSet: `${ShoppingBagIconSecond} 2x, ${ShoppingBagIconThird} 3x`,
  },
  {
    imageSrc: BillIcon,
    step: "2",
    title: "รอการชำระเงิน",
    imageSrcSet: `${BillIconSecond} 2x, ${BillIconThird} 3x`,
  },
  {
    imageSrc: InvestIcon,
    step: "3",
    title: "ชำระเงินและรับสินค้าแล้ว",
    imageSrcSet: `${InvestIconSecond} 2x, ${InvestIconThird} 3x`,
  },
];

export const CREDIT_LOGISTIC_STEPS = [
  {
    imageSrc: BillIcon,
    step: "1",
    title: "ได้รับคำสั่งซื้อแล้ว กำลังเตรียมสินค้า",
    imageSrcSet: `${BillIconSecond} 2x, ${BillIconThird} 3x`,
  },
  {
    imageSrc: ShippedIcon,
    step: "2",
    title: "จัดส่งแล้ว",
    imageSrcSet: `${ShippedIconSecond} 2x, ${ShippedIconThird} 3x`,
  },
  {
    imageSrc: InvestIcon,
    step: "3",
    title: "รอชำระเงิน",
    imageSrcSet: `${InvestIconSecond} 2x, ${InvestIconThird} 3x`,
  },
];

export const CREDIT_STEPS = [
  {
    imageSrc: BillIcon,
    step: "1",
    title: "ได้รับคำสั่งซื้อแล้ว กำลังเตรียมสินค้า",
    imageSrcSet: `${BillIconSecond} 2x, ${BillIconThird} 3x`,
  },
  {
    imageSrc: ShoppingBagIcon,
    step: "2",
    title: "สินค้าพร้อมรับแล้ว",
    imageSrcSet: `${ShoppingBagIconSecond} 2x, ${ShoppingBagIconThird} 3x`,
  },
  {
    imageSrc: InvestIcon,
    step: "3",
    title: "รอชำระเงิน",
    imageSrcSet: `${InvestIconSecond} 2x, ${InvestIconThird} 3x`,
  },
];

export default {};
