import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import { APP_BAR_A_VERSION, APP_BAR_B_VERSION } from '@/store/modules/app.store';
import { LOCAL_STORAGE_ACCESS_TOKEN } from '@/store/modules/user.store';
import {
  SET_APP_BAR_TYPE_ACTION,
  GET_USER_INFORMATION,
  CHANGE_SNACKBAR_COLOR,
  CHANGE_SNACKBAR_MESSAGE,
  SHOW_SNACKBAR,
} from '@/store/_actionTypes';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/Home/Home.vue'),
  // },
  {
    path: '/',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '../views/Shop/Shop.vue'),
    props: true,
  },
  {
    path: '/user-info',
    name: 'UserInfo',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
  },
  {
    path: '/order-history',
    name: 'OrderHistory',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
  },
  {
    path: '/request-product',
    name: 'RequestProduct',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
  },
  {
    path: '/request-product/offer',
    name: 'RequestProductOffer',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
  },
  {
    path: '/referral',
    name: 'Referral',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/Profile.vue'),
  },
  {
    path: '/quotation',
    name: 'Quotation',
    component: () => import(/* webpackChunkName: "quotation" */ '../views/Quotation/Quotation.vue'),
  },
  {
    path: '/order-history/:orderId',
    name: 'OrderHistoryDetails',
    component: () => import(/* webpackChunkName: "order-history-details" */ '../views/Profile/Profile.vue'),
  },
  {
    path: '/product/:productId',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product/Product.vue'),
  },
  {
    path: '/capsule-market',
    name: 'CapsuleMarket',
    component: () => import(/* webpackChunkName: "capsule-market" */ '../views/CapsuleMarket/CapsuleMarket.vue'),
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback/Feedback.vue'),
  },
  {
    path: '/anouncement',
    name: 'Announcement',
    component: () => import(/* webpackChunkName: "anouncement" */ '../views/Announcement/Announcement.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register/Register.vue'),
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs/ContactUs.vue'),
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutUs/AboutUs.vue'),
  },
  {
    path: '/delivery-info',
    name: 'DeliveryInfo',
    component: () => import(/* webpackChunkName: "delivery-info" */ '../views/DeliveryInfo/DeliveryInfo.vue')
  },
  {
    path: '/return-policy',
    name: 'ReturnPolicy',
    component: () => import(/* webpackChunkName: "return-policy" */ '../views/ReturnPolicy/ReturnPolicy.vue')
  },
  {
    path: '/why-2-buy',
    name: 'Why2Buy',
    component: () => import(/* webpackChunkName: "why-2-buy" */ '../views/Why2Buy/Why2Buy.vue')
  },
  {
    path: '/how-2-buy',
    name: 'How2Buy',
    component: () => import(/* webpackChunkName: "how-2-buy" */ '../views/How2Buy/How2Buy.vue')
  },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "forgetpassword" */ '../views/ForgetPassword/ForgetPassword.vue')
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword/ResetPassword.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(async (to, _from, next) => {
  const toPageName = to.name;
  const toPath = to.path;

  document.title = `Narinonlin | นรินทร์เภสัช`;

  if (toPageName === 'Home' || toPageName === 'AboutUs' || toPageName === 'ContactUs') {
    store.dispatch(`app/${SET_APP_BAR_TYPE_ACTION}`, { type: APP_BAR_B_VERSION });
  } else {
    store.dispatch(`app/${SET_APP_BAR_TYPE_ACTION}`, { type: APP_BAR_A_VERSION });
  }

  const publicPages = ['Home', 'Product', 'Shop', 'Announcement', 'ContactUs', 'AboutUs', 'Register', 'DeliveryInfo', 'ReturnPolicy', 'Why2Buy', 'How2Buy', 'ForgetPassword', 'ResetPassword'];
  const authRequired = !publicPages.includes(toPageName) && toPath !== '/404';
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

  if (authRequired || accessToken) {
    await store.dispatch(`user/${GET_USER_INFORMATION}`, accessToken);
    if (!localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
      store.dispatch(`app/${SHOW_SNACKBAR}`, true);
      store.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'กรุณาเข้าสู่ระบบก่อนใช้งาน');
      store.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'red');
      return;
    }
  }

  return next();
});

export default router;
