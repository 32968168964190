export const isPromotionValid = (start, end, isPromotion) => {
    const now = new Date();
    if (!isPromotion) return false
    return new Date(start) <= now && new Date(end) >= now;
};

export const isDiscountPromotionValid = (item) => {
    return isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion)
    && (item.promotionType === 2 || item.promotionType === 3)
};

export const isDiscountAmountPromotionValid = (item) => {
    return isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion) && item.promotionType === 2;
};

export const isDiscountPercentagePromotionValid = (item) => {
    return isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion) && item.promotionType === 3;
};

export const isFreeGiftPromotionValid = (item) => {
    return isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion) && item.promotionType === 1
};

export const isCapsuleAmountPromotionValid = (item) => {
    return isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion) && item.promotionType === 4
};

export const isCapsuleMultiplyPromotionValid = (item) => {
    return isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion) && item.promotionType === 5
};

export const calculateFreeBuyFreeGift = (buy, gift, qty) => Math.floor(qty / buy) * gift;

export const calculateDiscountAmount = (price, discount) => price - discount;

export const calculateDiscountPercentage = (price, discount) => price - (price * discount / 100);

export const calculateCapsuleAmount = (capsule, promotionCapsuleAmount, qty) => (capsule * qty) + promotionCapsuleAmount;

export const calculateCapsuleMultiply = (capsule, promotionCapsuleMultiply, qty) => capsule * promotionCapsuleMultiply * qty;

export const applyDiscountPromotion = (item, price) => {
    if (!isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion))
        return price;

    switch (item.promotionType) {
        case 2:
            return calculateDiscountAmount(price, parseInt(item.promotionDiscountAmount));
        case 3:
            return calculateDiscountPercentage(price, parseInt(item.promotionDiscountPercentage));
        default:
            return price;
    }
};

export const applyCapsulePromotion = (item, capsule, qty) => {
    if (!isPromotionValid(item.promotionStartdate, item.promotionEnddate, item.isPromotion))
        return capsule;

    switch (item.promotionType) {
        case 4:
            return calculateCapsuleAmount(capsule, item.promotionCapsuleAmount, qty);
        case 5:
            return calculateCapsuleMultiply(capsule, item.promotionCapsuleMultiply, qty);
        default:
            return capsule;
    }
};

export const getPriceRate = (item) => item.priceRates.filter(priceRate => priceRate.minQty <= item.qty).sort((a, b) => b.minQty - a.minQty)[0].priceUnit;

export default {};