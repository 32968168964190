<template>
  <div class="app-bar">
    <MobileVersion
      :contact-menus="contactMenus"
      :page-menus="pageMenus"
      :drawer="drawer"
      @setDrawer="setDrawer"
    />

    <v-app-bar
      app
      color="primary"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up app-bar-nav-icon"
        @click="setDrawer(!drawer)"
      />
      <div class="flex-row flex-wrap d-flex justify-space-between align-center app-container">
        <div class="hidden-sm-and-down app-container">
          <Menu
            :menu-list="contactMenus"
            :class-name="'d-flex flex-row text-white mr-10'"
          />
          <div class="d-flex align-center">
            <Menu :menu-list="pageMenus" />
            <UserInfo />
          </div>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import Menu from './Menu.vue';
import MobileVersion from './MobileVersion.vue';
import UserInfo from './UserInfo.vue';

export default {
  name: 'AVersion',
  components: {
    Menu,
    MobileVersion,
    UserInfo,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapGetters('app', ['getAppBarType']),
    ...mapState('app', ['contactMenus', 'pageMenus']),
    ...mapState('user', ['userInfo']),
  },
  methods: {
    setDrawer(drawer) {
      this.drawer = drawer;
    },
  },
};
</script>

<style lang="scss">
  .app-bar {
    color: white !important;
    height: 70px;
  }
  .app-container {
    width: 100%;
    display: contents;
  }
  .img {
    height: 16px;
    width: 16px;
  }
  .app-bar-nav-icon {
    .v-icon.v-icon {
      color: white !important;
    }
  }
  .v-toolbar {
    max-height: 40px !important;
  }
  .v-toolbar__content, .v-toolbar__extension {
    max-height: 40px !important;
  }
  .drawer {
    .v-navigation-drawer__content {
      background-color: #300463;
    }
    .theme--light.v-divider {
      border-color: white !important;
    }
  }
</style>
