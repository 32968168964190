import axios from 'axios';
import { get, post, getWithoutToken } from '@/helpers/axios';

let source;

export const getCategories = () => {
  try {
    return getWithoutToken('/products/categories', {});
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProducts = (searchValue, sort = 'latest', categoryFilter, minPrice, maxPrice, page = 1, limit = 32, accessToken = '') => {
  try {
    if (source) {
      source.cancel();
    }

    source = axios.CancelToken.source();
    const cancelToken = source.token;

    let query = { sort_by: sort, page, limit };
    if (!!searchValue) query['keyword'] = searchValue;
    if (categoryFilter.length !== 0) query['categories'] = categoryFilter.join();
    if (minPrice) query['price_min'] = minPrice;
    if (maxPrice) query['price_max'] = maxPrice;

    const params = new URLSearchParams(query);
    return !!accessToken
      ? get(`/products?${params}`, {}, accessToken, cancelToken)
      : getWithoutToken(`/products?${params}`, {}, cancelToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProduct = (productId, accessToken = '') => {
  try {
    return !!accessToken
      ? get(`/products/${productId}`, {}, accessToken)
      : getWithoutToken(`/products/${productId}`, {});
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRecommendation = (page = 1, limit = 20, accessToken = '') => {
  try {
    const query = { page, limit };
    const params = new URLSearchParams(query);
    return !!accessToken
      ? get(`/recommend_product?${params}`, {}, accessToken)
      : getWithoutToken(`/recommend_product?${params}`, {});
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getWishlist = (accessToken) => {
  try {
    return get('/products/favorite', {}, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addWishlist = (productId, accessToken) => {
  try {
    return post('/users/me/favorite/add', { productId }, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const removeWishlist = (productId, accessToken) => {
  try {
    return post('/users/me/favorite/remove', { productId }, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCapsuleConfig = (accessToken) => {
  try {
    return get('/capsule/config', { }, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createRequestProduct = (accessToken, payload) => {
  try {
    return post('/request_orders', payload, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const rejectedRequestProduct = (accessToken, payload) => {
  try {
    return post(`/request_orders/${payload.id}/actions/reject_offer`, {}, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const acceptedRequestProduct = (accessToken, payload) => {
  try {
    return post(`/request_orders/${payload.id}/actions/accept_offer`, {}, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRequestProduct = (accessToken, status = '', page = 1, limit = 10) => {
  try {
    // TODO-request-product: use axios to get request product
    let query = { page, limit };
    if (!!status) query = { ...query, status };

    const params = new URLSearchParams(query);
    const requestProducts = get(`/request_orders?${params}`, { }, accessToken);
    // mock return
    /*
    const requestProducts = {
      total: 5,
      items: [
        {
          product: {
            img:
              'https://www.neocellthailand.com/wp-content/uploads/2019/11/02-Marine-Collagen-600x600.jpg',
            name: 'NR002 ตัวอย่างชื่อยาที่จัดจำหน่าย ชื่อยายาวมากกว่าหนึ่งบรรทัด ชื่อยายาวสองบรรทัด',
            qty: '2 กล่อง',
          },
          updated_date: new Date(2019, 12, 1, 13, 56, 49),
          status: 'waiting',
        },
        {
          product: {
            img:
              'https://www.neocellthailand.com/wp-content/uploads/2019/11/02-Marine-Collagen-600x600.jpg',
            name: 'NR002 ตัวอย่างชื่อยาที่จัดจำหน่าย ชื่อยายาวมากกว่าหนึ่งบรรทัด ชื่อยายาวสองบรรทัด',
            qty: '1 ถุง',
          },
          updated_date: new Date(2019, 12, 1, 13, 56, 49),
          status: 'accepted',
        },
        {
          product: {
            img:
              'https://www.neocellthailand.com/wp-content/uploads/2019/11/02-Marine-Collagen-600x600.jpg',
            name: 'NR001 ตัวอย่างชื่อยาที่จัดจำหน่าย ชื่อยายาวหนึ่งบรรทัด',
            qty: '2 กล่อง',
          },
          updated_date: new Date(2019, 12, 1, 13, 56, 49),
          status: 'rejected',
        },
        {
          product: {
            img:
              'https://www.neocellthailand.com/wp-content/uploads/2019/11/02-Marine-Collagen-600x600.jpg',
            name: 'NR002 ตัวอย่างชื่อยาที่จัดจำหน่าย ชื่อยายาวมากกว่าหนึ่งบรรทัด ชื่อยายาวสองบรรทัด',
            qty: '2 กล่อง',
          },
          updated_date: new Date(2019, 12, 1, 13, 56, 49),
          status: 'waiting',
        },
        {
          product: {
            img:
              'https://www.neocellthailand.com/wp-content/uploads/2019/11/02-Marine-Collagen-600x600.jpg',
            name: 'NR002 ตัวอย่างชื่อยาที่จัดจำหน่าย ชื่อยายาวมากกว่าหนึ่งบรรทัด ชื่อยายาวสองบรรทัด',
            qty: '1 ถุง',
          },
          updated_date: new Date(2019, 12, 1, 13, 56, 49),
          status: 'accepted',
        },
      ]
    };
    */

    return requestProducts
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
