<template>
  <v-navigation-drawer
    v-model="drawerValue"
    disable-resize-watcher
    app
    temporary
    class="drawer"
  >
    <div class="pa-4">
      <UserInfo />
      <v-divider
        v-if="isLoggedIn"
        class="mt-4 mb-4"
      />
      <Menu
        class-name="d-flex flex-column my-4"
        :menu-list="pageMenus"
      />
      <v-divider class="mb-4" />
      <Menu
        class-name="d-flex flex-column"
        :menu-list="contactMenus"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

import Menu from './Menu.vue';
import UserInfo from './UserInfo.vue';

export default {
  name: 'MobileVersion',
  components: {
    Menu,
    UserInfo,
  },
  props: {
    contactMenus: {
      type: Array,
      default: () => []
    },
    pageMenus: {
      type: Array,
      default: () => []
    },
    drawer: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    drawerValue: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit('setDrawer', value);
      }
    }
  }
}
</script>