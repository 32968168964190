import dayjs from 'dayjs';
import 'dayjs/locale/th'

export const formatDate = (date) => dayjs(date).locale('th').format('DD MMM YYYY HH:mm:ss');

export const formatDateWithTimePrefix = (date) =>  dayjs(date).locale('th').format('DD MMM YYYY, HH:mm:ss น.');

export const formatDateWithoutTime = (date) =>  dayjs(date).locale('th').format('DD MMM YYYY')

export default {};
