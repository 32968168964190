import Vue from 'vue';
import Vuex from 'vuex';

import {
  getProduct,
  getProducts,
} from '@/services/product';

import {
  GET_PRODUCT,
  GET_PRODUCT_RELATED,
} from '@/store/_actionTypes';

import {
  SET_PRODUCT,
  SET_PRODUCT_RELATED,
} from '@/store/_mutationTypes';

Vue.use(Vuex);

const states = {
  product: {},
  productRelated: [],
};

const getters = {

};

const actions = {
  async [GET_PRODUCT]({ commit }, { productId, accessToken }) {
    const result = await getProduct(productId, accessToken);
    commit(SET_PRODUCT, result);
  },
  async [GET_PRODUCT_RELATED]({ commit }, { productId, categoryId, accessToken }) {
    const result = await getProducts('', 'latest', [categoryId], undefined, undefined, 1, 5, accessToken);
    const resultFilter = result.results.filter(item => item.id != productId && item.visibility !== -1)
    commit(SET_PRODUCT_RELATED, resultFilter.slice(0, 4));
  },
};

const mutations = {
  [SET_PRODUCT](state, product) {
    state.product = product;
  },
  [SET_PRODUCT_RELATED](state, productRelated) {
    state.productRelated = productRelated;
  },
};

export default {
  namespaced: true,
  state: states,
  getters,
  actions,
  mutations,
};
