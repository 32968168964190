import KerryIcon from "@/assets/images/delivery-provider/kerry.png";
import Kerry2xIcon from "@/assets/images/delivery-provider/kerry@2x.png";
import Kerry3xIcon from "@/assets/images/delivery-provider/kerry@3x.png";
import NimIcon from "@/assets/images/delivery-provider/nim-express.png";
import Nim2xIcon from "@/assets/images/delivery-provider/nim-express@2x.png";
import Nim3xIcon from "@/assets/images/delivery-provider/nim-express@3x.png";
import ScgIcon from "@/assets/images/delivery-provider/scg-express.png";
import Scg2xIcon from "@/assets/images/delivery-provider/scg-express@2x.png";
import Scg3xIcon from "@/assets/images/delivery-provider/scg-express@3x.png";
import ThailandPostIcon from "@/assets/images/delivery-provider/thailand_post.jpg";

export const PROVIDERS = [
  {
    id: 1,
    name: 'Kerry Express',
    iconImage: KerryIcon,
    iconImageSrcSet: `${Kerry2xIcon} 2x, ${Kerry3xIcon} 3x`,
    src: 'https://th.kerryexpress.com/th/track/'
  },
  {
    id: 2,
    name: 'NIM Express',
    iconImage: NimIcon,
    iconImageSrcSet: `${Nim2xIcon} 2x, ${Nim3xIcon} 3x`,
    src: 'https://www.nimexpress.com/web/p/home'
  },
  {
    id: 3,
    name: 'SCG Express',
    iconImage: ScgIcon,
    iconImageSrcSet: `${Scg2xIcon} 2x, ${Scg3xIcon} 3x`,
    src: 'https://www.scgexpress.co.th/tracking'
  },
  {
    id: 4,
    name: 'ไปรษณีย์ไทย',
    iconImage: ThailandPostIcon,
    src: 'https://track.thailandpost.co.th/'
  }
];

export const getProviderDetail = (type) => PROVIDERS.find(provider => provider.id === type);

export const getProviderDetailByName = (type) => PROVIDERS.find(provider => provider.name === type);

export default {};
