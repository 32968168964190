import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/pages/main/router';
import {
  CREATE_SHIPPING,
  LOGIN,
  LOGOUT,
  SHOW_LOGIN_DIALOG,
  SHOW_SNACKBAR,
  GET_USER_INFORMATION,
  CHANGE_SNACKBAR_MESSAGE,
  CHANGE_SNACKBAR_COLOR,
  UPDATE_FAVORITES,
  UPDATE_SHIPPING,
  UPDATE_DEFAULT_SHIPPING,
  UPDATE_USER_INFORMATION,
  CONFIRM_USER,
} from '@/store/_actionTypes';
import {
  SET_USER_INFO_MUTATION,
  SET_ACCESS_TOKEN,
  SET_LOGIN_ERROR_MESSAGE,
  SET_SHIPPINGS,
  SET_FAVORITES,
  SET_CERT_FILE,
  SET_PERMISSION_FILE,
} from '@/store/_mutationTypes';

import { login } from '@/services/login';
import { getImage } from '@/services/upload-image';
import {
  createShipping,
  getUserInformation,
  updateUserInformation,
  updateShipping,
  updateDefaultShipping,
  confirmUser,
} from '@/services/user/user';

Vue.use(Vuex);

export const LOCAL_STORAGE_ACCESS_TOKEN = 'access_token';
import { LOCAL_STORAGE_CART_ITEMS } from './cart.store';

const states = {
  userInfo: undefined,
  accessToken: '',
  errorMessage: '',
  shippings: [],
  favorites: [],
  permissionFile: null,
  certificationFile: null,
};

const defineGetters = {
  getUserInfo: (state) => state.userInfo,
  getUserCredit: (state) => state.userInfo?.credit,
  getCurrentCapsuletPoint: ({ userInfo }) => userInfo?.redeemPoint || 0,
  getErrorMessage: (state) => state.errorMessage,
  getRefCode: (state) => state.userInfo?.referCode || 'กรุณาติดต่อเจ้าหน้าที่เพื่อรับ Referral code',
  isLoggedIn: (state) => !!state.userInfo,
  getCapsule: (state) => state.userInfo?.capsules || '0',
  getDefaultDeliveryLocation: ({ shippings }) =>
    shippings.find((shipping) => shipping.isDefault),
};

const actions = {
  async [CONFIRM_USER]({}, token) {
    try {
      await confirmUser({ token });
      this.dispatch(`app/${SHOW_SNACKBAR}`, true);
      this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'green');
      this.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'ยืนยันสำเร็จ โปรด login อีกครั้งเพื่อเข้าสู่ระบบ');
    } catch (e) {
      console.error(e.message);
    }
  },
  async [LOGIN]({ commit }, { email, password }) {
    try {
      const result = await login(email, password);
      if (!result) {
        return;
      }

      localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, result.accessToken);
      localStorage.removeItem(LOCAL_STORAGE_CART_ITEMS);

      commit(SET_LOGIN_ERROR_MESSAGE, '');
      commit(SET_ACCESS_TOKEN, result.accessToken);
      commit(SET_USER_INFO_MUTATION, result.user);
      commit(SET_SHIPPINGS, result.user?.shippings || []);
      commit(SET_FAVORITES, result?.favorites || []);
      this.dispatch(`app/${SHOW_LOGIN_DIALOG}`, false);

      this.dispatch(`app/${SHOW_SNACKBAR}`, true);
      this.dispatch(`app/${CHANGE_SNACKBAR_COLOR}`, 'green');
      this.dispatch(`app/${CHANGE_SNACKBAR_MESSAGE}`, 'เข้าสู่ระบบสำเร็จ');

      setTimeout(() => {
        router.go();
      }, 2000);
    } catch (e) {
      commit(SET_LOGIN_ERROR_MESSAGE, e.response.data.error);
    }
  },
  [LOGOUT]({ commit }) {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_CART_ITEMS);

    commit(SET_ACCESS_TOKEN, undefined);
    commit(SET_SHIPPINGS, undefined)
    commit(SET_USER_INFO_MUTATION, undefined);
    commit(SET_FAVORITES, undefined);

    window.location.href = '/';
  },
  async [GET_USER_INFORMATION]({ commit }, accessToken) {
    try {
      const result = await getUserInformation(accessToken);
      const permissionFile = await getImage(result.permissionFileId, accessToken);
      const certificationFile = await getImage(result.certificationFileId, accessToken);
      commit(SET_USER_INFO_MUTATION, result);
      commit(SET_ACCESS_TOKEN, accessToken);
      commit(SET_CERT_FILE, certificationFile?.url);
      commit(SET_PERMISSION_FILE, permissionFile?.url);
      commit(SET_SHIPPINGS, result?.shippings || []);
      commit(SET_FAVORITES, result?.favorites || []);
    } catch (err) {
      console.error(err.message)
    }
  },
  async [UPDATE_USER_INFORMATION]({ commit, state }, userInfo) {
    const result = await updateUserInformation(state.accessToken, userInfo);
    commit(SET_USER_INFO_MUTATION, result);
    return result;
  },
  async [CREATE_SHIPPING]({ commit, state, getters }, shipping) {
    const result = await createShipping(state.accessToken, shipping, getters.getUserInfo?.id);
    commit(SET_SHIPPINGS, [...state.shippings, result]);
    return result;
  },
  async [UPDATE_SHIPPING]({ commit, state }, { shippingId, shipping }) {
    const result = await updateShipping(state.accessToken, shippingId, shipping);
    const newShipping = [...state.shippings].map(item => {
      if (item.id === shippingId) {
        return { id: shippingId, ...shipping };
      }

      return item;
    });
    commit(SET_SHIPPINGS, newShipping);
    return result;
  },
  async [UPDATE_DEFAULT_SHIPPING]({ commit, state }, { accessToken, shippingId }) {
    const newShipping = [...state.shippings].map(item => {
      if (item.id === shippingId) {
        item.isDefault = true;
      } else {
        item.isDefault = false;
      }
      return item;
    });
    commit(SET_SHIPPINGS, newShipping);
    return updateDefaultShipping(accessToken, shippingId);
  },
  async [UPDATE_FAVORITES]({ commit }, favorites) {
    commit(SET_FAVORITES, favorites);
  },
};

const mutations = {
  [SET_USER_INFO_MUTATION](state, userInfo) {
    state.userInfo = userInfo;
  },
  [SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },
  [SET_LOGIN_ERROR_MESSAGE](state, message) {
    state.errorMessage = message;
  },
  [SET_SHIPPINGS](state, shippings) {
    state.shippings = shippings;
  },
  [SET_FAVORITES](state, favorites) {
    state.favorites = favorites;
  },
  [SET_CERT_FILE](state, url) {
    state.certificationFile = url;
  },
  [SET_PERMISSION_FILE](state, url) {
    state.permissionFile = url;
  },
};

export default {
  namespaced: true,
  state: states,
  getters: defineGetters,
  actions,
  mutations,
};
