// app
export const SET_APP_BAR_TYPE_ACTION = 'SET_APP_BAR_TYPE_ACTION';
export const MOBILE_SCREEN_SIZE = 'MOBILE_SCREEN_SIZE';
export const SHOW_LOGIN_DIALOG = 'SHOW_LOGIN_DIALOG';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const SHOW_SNACKBAR_COMMON_ERROR_ACTION =
  'SHOW_SNACKBAR_COMMON_ERROR_ACTION';
export const CHANGE_SNACKBAR_MESSAGE = 'CHANGE_SNACKBAR_MESSAGE';
export const CHANGE_SNACKBAR_COLOR = 'CHANGE_SNACKBAR_COLOR';
export const REDIRECT_TO_ACTION = 'REDIRECT_TO_ACTION';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';

// profile
export const CURRENT_SELECTED_TAB = 'CURRENT_SELECTED_TAB';
export const NAVIGATE_USER_PROFILE = 'NAVIGATE_USER_PROFILE';

// user
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const CREATE_SHIPPING = 'CREATE_SHIPPING';
export const UPDATE_SHIPPING = 'UPDATE_SHIPPING';
export const UPDATE_FAVORITES = 'UPDATE_FAVORITES';
export const UPDATE_DEFAULT_SHIPPING = 'UPDATE_DEFAULT_SHIPPING';
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
export const CONFIRM_USER = 'CONFIRM_USER';

// quotation
export const GET_QUOTATION_BY_ID = 'GET_QUOTATION_BY_ID';
export const CONFIRM_QUOTATION = 'CONFIRM_QUOTATION';
export const CONFIRM_COUPON = 'CONFIRM_COUPON';

// confirm-payment
export const SHOW_CONFIRM_PAYMENT_DIALOG = 'SHOW_CONFIRM_PAYMENT_DIALOG';
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';

// CapsuleMarket
export const FETCH_CAPSULE_MARKET_ITEMS = 'FETCH_CAPSULE_MARKET_ITEMS';
export const SHOW_DIALOG_REDEEM_SUCCESS = 'SHOW_DIALOG_REDEEM_SUCCESS';
export const REDEEM_ITEMS = 'REDEEM_ITEMS';

// order
export const SHOW_CANCEL_ORDER_DIALOG = 'SHOW_CANCEL_ORDER_DIALOG';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const GET_ORDERS_ACTION = 'GET_ORDERS_ACTION';
export const GET_ORDER_BY_ID_ACTION = 'GET_ORDER_BY_ID_ACTION';

// request-product
export const SHOW_REQUEST_PRODUCT_DIALOG = 'SHOW_REQUEST_PRODUCT_DIALOG';
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const GET_REQUEST_PRODUCT = 'GET_REQUEST_PRODUCT';
export const REJECTED_REQUEST_PRODUCT = 'REJECTED_REQUEST_PRODUCT';
export const ACCEPTED_REQUEST_PRODUCT = 'ACCEPTED_REQUEST_PRODUCT';

// upload-image
export const UPLOAD_IMAGE_ACTION = 'UPLOAD_IMAGE_ACTION';
export const SHOW_UPLOAD_PROGRESS = 'SHOW_UPLOAD_PROGRESS';

// shop
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const UPDATE_CATEGORY_FILTER = 'UPDATE_CATEGORY_FILTER';
export const UPDATE_MIN_PRICE = 'UPDATE_MIN_PRICE';
export const UPDATE_MAX_PRICE = 'UPDATE_MAX_PRICE';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_KEYWORD = 'UPDATE_KEYWORD';
export const GET_PRODUCTS_SUGGESTION = 'GET_PRODUCTS_SUGGESTION';

// cart
export const INIT_CART = 'INIT_CART';
export const ADD_ITEM = 'ADD_ITEM';
export const UPDATE_QTY = 'UPDATE_QTY';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UPDATE_SELECTED = 'UPDATE_SELECTED';
export const CLEAR_ITEM = 'CLEAR_ITEM';

// product
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_RELATED = 'GET_PRODUCT_RELATED';
export const GET_RECOMMENDATION = 'GET_RECOMMENDATION';

// wishlist
export const GET_WISHLIST_PRODUCT = 'GET_WISHLIST_PRODUCT';
export const ADD_WISHLIST_PRODUCT = 'ADD_WISHLIST_PRODUCT';
export const REMOVE_WISHLIST_PRODUCT = 'REMOVE_WISHLIST_PRODUCT';

//announcement
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';

export default {};
